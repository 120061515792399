import {
  useEffect,
  FunctionComponent,
  useCallback,
  useContext,
  useRef,
} from "react";
import { BacktestTradingReport } from "../../interfaces/backtester/BacktestTradingReport";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserId } from "../../utils/UserUtil";
import { BacktesterService } from "../../services/BacktesterService";
import { WebPubSubClient } from "@azure/web-pubsub-client";
import {
  HasJoinedPubSubGroup,
  SetCancelBacktestContext,
  SetHasJoinedPubSubGroup,
} from "./BacktesterPage";
import { ToastContext } from "../../App";

interface TradingReportProviderProps {
  setBacktestTradingReport: React.Dispatch<
    React.SetStateAction<BacktestTradingReport | undefined>
  >;
  cancelBacktest: boolean;
}

const RealtimeTradingReportProvider: FunctionComponent<
  TradingReportProviderProps
> = (props: TradingReportProviderProps) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const setCancelBacktest = useContext(SetCancelBacktestContext);
  const triggerToast = useContext(ToastContext);
  const hasJoinedGroup = useContext(HasJoinedPubSubGroup);
  const setHasJoinedGroup = useContext(SetHasJoinedPubSubGroup);
  const clientRef = useRef<WebPubSubClient | null>(null);

  const getUserIdFromUser = useCallback(() => {
    if (!user) return null;
    return getUserId(user)?.value ?? null;
  }, [user]);

  const userId = getUserIdFromUser();

  const handleJoinGroup = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;

    const response = await BacktesterService.joinGroup(token);
    clientRef.current = new WebPubSubClient(response.data.pubsub_url);

    const client = clientRef.current;
    setHasJoinedGroup(client !== null);

    if (client && userId && !hasJoinedGroup) {
      //console.log("Joining group: " + userId); // Log userId
      client.joinGroup(userId);
      client.start();
    }
  }, [userId, hasJoinedGroup, clientRef, setHasJoinedGroup]);

  const handleCancelBacktest = useCallback(async () => {
    if (!userId) return;
    const token = await getAccessTokenSilently();
    if (!token) return;
    const response = await BacktesterService.cancelBacktest(token);
    triggerToast(response.data.message, "info", null);
    setTimeout(() => {
      setCancelBacktest(false);
    }, 4000);
  }, [getAccessTokenSilently, userId, triggerToast]);

  useEffect(() => {
    if (!hasJoinedGroup) handleJoinGroup();
    setHasJoinedGroup(clientRef.current !== null);
  }, [clientRef, setHasJoinedGroup, hasJoinedGroup, handleJoinGroup]);


  useEffect(() => {
    const client = clientRef.current;

    if (client && hasJoinedGroup) {
      client.on("group-message", (e) => {
        let data;
        data = e.message.data;
        props.setBacktestTradingReport(
          JSON.parse(data) as BacktestTradingReport
        );
      });

      if (props.cancelBacktest) {
        handleCancelBacktest();
      }
    }

    // Cleanup
    return () => {};
  }, [userId, props.cancelBacktest, clientRef, hasJoinedGroup]);

  return <></>;
};

export default RealtimeTradingReportProvider;
