import "./currency-logo-array.scss";
import { Avatar, MantineSize, Popover, Tooltip } from "@mantine/core";
import { useCallback, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { Currency } from "../../../interfaces/Currency";
import { getCurrencyUrl } from "../../../utils/cdnUtil";

interface Props {
  currencies: Currency[] | undefined;
  previewAmount: number;
  size: MantineSize;
  expandable: boolean;
  holdingCurrencies?: string[];
  buyingCurrencies?: string[];
  sellingCurrencies?: string[];
  id?: string;
}

export function CurrencyLogoArray(props: React.PropsWithChildren<Props>) {
  const [opened, setOpened] = useState(false);

  const getTooltipExtra = useCallback(
    (currency: Currency) => {
      if (props.sellingCurrencies?.includes(currency.currency_name)) {
        return "Selling...";
      }
      if (props.buyingCurrencies?.includes(currency.currency_name)) {
        return "Buying...";
      }
      if (props.holdingCurrencies?.includes(currency.currency_name)) {
        return "Holding";
      }
    },
    [props.holdingCurrencies, props.buyingCurrencies, props.sellingCurrencies]
  );

  return (
    <div
      className="currency-array-container"
      id={props.id}
      style={{
        gap: props.size === "xs" ? "0.1rem" : "0.2rem",
      }}
    >
      {props.currencies?.slice(0, props.previewAmount).map((currency, idx) => {
        const url = getCurrencyUrl(currency);
        const tooltipLabel = `#${currency.cmc_rank} ${currency.currency_name}`;
        const tooltipExtra = getTooltipExtra(currency);
        return (
          <Tooltip
            key={idx}
            className={
              "currency-array-item" +
              (props.buyingCurrencies?.includes(currency.currency_name)
                ? " buy"
                : "") +
              (props.sellingCurrencies?.includes(currency.currency_name)
                ? " sell"
                : "") +
              (props.holdingCurrencies?.includes(currency.currency_name)
                ? " holding"
                : "")
            }
            radius={"xs"}
            label={
              <>
                <label>{tooltipLabel}</label>
                <br />
                {tooltipExtra && (
                  <label>
                    <strong>{tooltipExtra}</strong>
                  </label>
                )}
              </>
            }
          >
            <Avatar src={url} size={props.size} radius={"lg"} />
          </Tooltip>
        );
      })}
      {props.currencies &&
      props.currencies.length > props.previewAmount &&
      props.expandable ? (
        <Popover
          withArrow
          onMouseOver={() => setOpened(true)}
          onMouseLeave={() => setOpened(false)}
          position="right"
          classNames={{
            inner: "notifications-popover-inner",
            target: "notifications-popover-target",
            arrow: "notifications-popover-arrow",
            body: "notifications-popover-body",
            root: "notifications-popover-root",
            wrapper: "notifications-popover-wrapper",
            popover: "notifications-popover-popover",
          }}
          width={
            props.currencies.length - props.previewAmount > 5 ? 260 : "auto"
          }
          opened={opened}
          onClose={() => setOpened(false)}
          target={<FiPlus onClick={() => setOpened((o) => !o)} />}
        >
          <div className="currency-array-popover">
            {props.currencies
              .slice(props.previewAmount)
              .map((currency, idx) => {
                const url = getCurrencyUrl(currency);
                const tooltipLabel = `#${currency.cmc_rank} ${currency.currency_name}`;

                return (
                  <Tooltip
                    key={idx}
                    className="currency-array-item"
                    style={{ padding: "3px" }}
                    label={tooltipLabel}
                  >
                    <Avatar src={url} size={props.size} radius={"lg"} />
                  </Tooltip>
                );
              })}
          </div>
        </Popover>
      ) : (
        <></>
      )}
    </div>
  );
}
