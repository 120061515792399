import "./strategy-page.scss";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ToastContext,
  ApplicationSettingsContext,
  UpdateCurrencyVibranceContext,
} from "../../App";
import { StrategyCenter } from "../../components/strategy-body/StrategyCenter";
import { Currency } from "../../interfaces/Currency";
import {
  Case,
  EntryCriteria,
  ExitCriteria,
  StrategyVersion,
  Header,
} from "../../interfaces/strategyInterfaces/Strategy";
import {
  tryDeleteUnsavedStrategy,
  tryGetStoredStrategy,
  tryGetUnsavedStrategy,
} from "../../utils/CachedDataUtil";
import { compareCurrencyRank } from "../../utils/SortUtils";
import {
  useDocumentTitle,
  useForceUpdate,
  useMediaQuery,
  useViewportSize,
} from "@mantine/hooks";
import { isEqual } from "../../utils/ObjectEqualsUtil";
import { useAuth0 } from "@auth0/auth0-react";
import { StrategyManagerService } from "../../services/StrategyManagerService";
import { CurrencyContext } from "../../shared-service-contexts/CurrencyContext";
import { IndicatorsContext } from "../../shared-service-contexts/IndicatorContext";
import { ApplicationIndicators } from "../../interfaces/Indicators";
import { NeotonLoader } from "../../components/custom-loaders/NeotonLoader";
import { StrategyHeaderComponent } from "../../components/strategy-header/StrategyHeaderComponent";
import { Modal } from "@mantine/core";
import {
  IndicatorInfosContext,
  IndicatorsInfo,
} from "../../shared-service-contexts/IndicatorsInfoContext";
import { StrategyTutorial } from "../../components/tutorials/StrategyTutorial";
import { motion } from "framer-motion";
import { generateRandomName } from "../../utils/RandomNameGenerator";
import { CommonButton } from "../../components/buttons/neoton-common-button/CommonButton";
import { CandleSize } from "../../components/strategy-body/StrategyHelperModal";
import {
  SelectedCurrencyContext,
  SetSelectedCurrencyContext,
} from "../../components/shared-context/HandleCurrencyContext";
interface Props {
  activeTheme: string;
}

export default function StrategyPage(props: React.PropsWithChildren<Props>) {
  const applicationSettings = useContext(ApplicationSettingsContext);
  const triggerToast = useContext(ToastContext);
  const updateCurrencyVibrances = useContext(UpdateCurrencyVibranceContext);

  const { getAccessTokenSilently } = useAuth0();
  const forceUpdate = useForceUpdate();
  const compact = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const { strategyId, versionId } = useParams();

  const location = useLocation();
  const [strategy, setStrategy] = useState<StrategyVersion | undefined>();
  const [criteriaTestResource, setCriteriaTestResource] = useState<
    CriteriaTestResource | undefined
  >(undefined);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [loadingStrategy, setLoadingStrategy] = useState(true);

  const [bodyRestored, setBodyRestored] = useState(false);
  const [updateable, setUpdateable] = useState(false);

  const [restorePrompt, setRestorePrompt] = useState(false);
  const unsavedStrategy = useMemo(() => {
    return tryGetUnsavedStrategy();
  }, []);

  const [tradingCurrencies, setTradingCurrencies] = useState<Currency[]>([]);

  useDocumentTitle(
    strategy
      ? `edit ${strategy.name} ${updateable ? "- unsaved" : ""}`
      : "Loading strategy ..."
  );

  const [selectedCase, setSelectedCase] = useState<Case | undefined>();

  const recoverStrategyFromLocalStorage = useCallback(() => {
    const storedStrategy = tryGetStoredStrategy();
    if (!storedStrategy) {
      console.error("Could not recover stored strategy");
      return;
    }
    return storedStrategy;
  }, []);

  const fetchCurrencies = useCallback(async () => {
    const _token = await getAccessTokenSilently();
    try {
      return await StrategyManagerService.blueprint.getCurrencies(_token);
    } catch (error) {
      console.log("error", error);
    }
  }, [getAccessTokenSilently]);

  const currenciesQuery = useQuery("Currencies", fetchCurrencies, {
    keepPreviousData: true,
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    retryDelay: 4000,
    onSuccess: (data) => {
      if (!data || !updateCurrencyVibrances) return;
      updateCurrencyVibrances(data);
    },
  });

  const setTradingCurrenciesFromStrategy = useCallback(
    (_strategy: StrategyVersion) => {
      if (!_strategy) return;
      if (currenciesQuery.data === undefined) return;
      if (!_strategy.body.cases) return;
      // find all curencncy names in the strategy
      const currencyNames: string[] = [];
      _strategy.body.cases.forEach((strategyCase) => {
        if (!strategyCase.included_currencies) return;
        strategyCase.included_currencies.forEach((currencyName) => {
          if (!currencyNames.includes(currencyName)) {
            currencyNames.push(currencyName);
          }
        });
      });
      const _newTradingCurrencies = currenciesQuery.data.filter((currency) =>
        currencyNames.includes(currency.currency_name)
      );
      setTradingCurrencies(_newTradingCurrencies);
    },
    [setTradingCurrencies, currenciesQuery.data]
  );

  const updateBody = useCallback(
    (_strategy: StrategyVersion) => {
      setStrategy(_strategy);
      const recoveredStrategy = recoverStrategyFromLocalStorage();
      if (recoveredStrategy)
        setUpdateable(!isEqual(_strategy, recoveredStrategy));
      setTradingCurrenciesFromStrategy(_strategy);
      forceUpdate();
    },
    [
      forceUpdate,
      setStrategy,
      recoverStrategyFromLocalStorage,
      setUpdateable,
      setTradingCurrenciesFromStrategy,
    ]
  );

  //on initial load, check if there is a unsaved strategy matching the strategyId and versionId
  useEffect(() => {
    if (location.state && location.state.disablePrompt) return;
    if (
      unsavedStrategy &&
      unsavedStrategy.strategy_id === strategyId &&
      unsavedStrategy.version_id === versionId
    ) {
      setRestorePrompt(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timer = setInterval(() => {
      if (updateable && strategy) {
        localStorage.setItem("unsavedStrategy", JSON.stringify(strategy));
      }
    }, 5000); // Save every 5 seconds
    if (!updateable) localStorage.removeItem("unsavedStrategy");

    return () => clearInterval(timer); // cleanup on unmount
  }, [updateable, strategy]);

  const updateHeader = useCallback(
    (_strategyHeader: Header) => {
      if (!strategy) return;
      const _strategy = strategy;
      _strategy.header = _strategyHeader;
      updateBody(_strategy);
      forceUpdate();
    },
    [strategy, forceUpdate, updateBody]
  );

  const updateFixedPoolComposition = useCallback(
    (_strategy: StrategyVersion) => {
      // get all the currencies in the fixed pools
      if (!_strategy.header.fixed_pool_composition) return;
      const fixedPoolComposition = _strategy.header.fixed_pool_composition;
      const fixedPoolCurrencies: string[] = [];
      fixedPoolCurrencies.push(...fixedPoolComposition.high);
      fixedPoolCurrencies.push(...fixedPoolComposition.medium);
      fixedPoolCurrencies.push(...fixedPoolComposition.low);
      // get all the currencies in the strategy
      const currencyNames: string[] = [];

      _strategy.body.cases.forEach((strategyCase) => {
        if (!strategyCase.included_currencies) return;
        strategyCase.included_currencies.forEach((currencyName) => {
          if (!currencyNames.includes(currencyName)) {
            currencyNames.push(currencyName);
          }
        });
      });

      // we compare the two arrays to we need to add or remove currencies from the fixed pool composition
      const currenciesToAdd = currencyNames.filter(
        (currencyName) => !fixedPoolCurrencies.includes(currencyName)
      );
      const currenciesToRemove = fixedPoolCurrencies.filter(
        (currencyName) => !currencyNames.includes(currencyName)
      );
      // add the currencies to the fixed pool composition
      const _updatedHeader = _strategy.header;
      _updatedHeader.fixed_pool_composition?.high.push(...currenciesToAdd);

      // remove the currencies from the fixed pool composition
      if (_updatedHeader.fixed_pool_composition?.high) {
        _updatedHeader.fixed_pool_composition.high =
          _updatedHeader.fixed_pool_composition?.high.filter(
            (currencyName) => !currenciesToRemove.includes(currencyName)
          );
      }
      if (_updatedHeader.fixed_pool_composition?.medium) {
        _updatedHeader.fixed_pool_composition.medium =
          _updatedHeader.fixed_pool_composition?.medium.filter(
            (currencyName) => !currenciesToRemove.includes(currencyName)
          );
      }
      if (_updatedHeader.fixed_pool_composition?.low) {
        _updatedHeader.fixed_pool_composition.low =
          _updatedHeader.fixed_pool_composition?.low.filter(
            (currencyName) => !currenciesToRemove.includes(currencyName)
          );
      }
      updateHeader(_updatedHeader);
      forceUpdate();
    },
    [updateHeader, forceUpdate]
  );

  const fetchIndicators = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const response = await StrategyManagerService.blueprint.getIndicators(
        token
      );
      return response.data as ApplicationIndicators;
    } catch (error) {}
  }, [getAccessTokenSilently]);

  const indicatorsQuery = useQuery(["Indicators"], fetchIndicators, {
    keepPreviousData: true,
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const fetchIndicatorInfo = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const response = await StrategyManagerService.blueprint.getIndicatorsInfo(
        token
      );
      return response.data as IndicatorsInfo;
    } catch (error) {}
  }, [getAccessTokenSilently]);

  const indicatorInfoQuery = useQuery(["IndicatorsInfo"], fetchIndicatorInfo, {
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const restoreStrategy = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    if (!strategyId || !versionId) return;
    const response = await StrategyManagerService.blueprint.loadStrategy(
      token,
      strategyId,
      versionId
    );
    if (!response.payload) return;
    setTradingCurrenciesFromStrategy(response.payload);
    setStrategy(undefined);
    setStrategy(response.payload);
    setUpdateable(false);
    setRestorePrompt(false);
    setSelectedCase(undefined);
    forceUpdate();
  }, [
    setStrategy,
    setSelectedCase,
    setTradingCurrenciesFromStrategy,
    forceUpdate,
    setUpdateable,
    getAccessTokenSilently,
    strategyId,
    versionId,
  ]);

  const deleteCase = useCallback(
    (caseName: string) => {
      if (!strategy) return;
      const cases = strategy.body.cases;
      strategy.body.cases = cases?.filter(
        (strategyCase) => strategyCase.name !== caseName
      );
      if (caseName === selectedCase?.name) {
        setSelectedCase(undefined);
      }
      updateBody(strategy);
      setTradingCurrenciesFromStrategy(strategy);
    },
    [
      strategy,
      updateBody,
      setTradingCurrenciesFromStrategy,
      selectedCase,
      setSelectedCase,
    ]
  );

  const addCase = useCallback(
    (
      caseName: string,
      entry_criteria?: EntryCriteria,
      exit_criteria?: ExitCriteria,
      copiedCase?: Case
    ) => {
      if (!strategy) return;
      const cases = strategy.body.cases;
      let strategyCase: Case = {
        name: caseName,
        entry_criteria: entry_criteria
          ? entry_criteria
          : {
              candle_size_1D: [],
              candle_size_1h: [],
              candle_size_5m: [],
            },
        exit_criteria: exit_criteria
          ? exit_criteria
          : {
              candle_size_1D: [],
              candle_size_1h: [],
              candle_size_5m: [],
            },
        weight: 1.0,
        daily_frequency_limit: 1,
        include_all: false,
        included_currencies: [],
        position_type: "market",
        stop_profit: 10,
        stop_profit_candle_size: "candle_size_1D",
        stoploss: -10,
        stoploss_candle_size: "candle_size_1D",
        trailing_stoploss: false,
      };
      if (copiedCase) {
        strategyCase = { ...copiedCase, name: caseName };
      }
      if (!cases) {
        strategy.body.cases = [strategyCase];
        setStrategy(strategy);
        return;
      }
      strategy.body.cases?.push(strategyCase);
      setSelectedCase(strategyCase);
      updateBody(strategy);
    },
    [strategy, setStrategy, setSelectedCase, updateBody]
  );

  const filterNonAvailableCurrencies = useCallback(
    (currencyList: Currency[] | undefined) => {
      if (!currencyList || !strategy) return undefined;
      if (strategy.header.currency_pair === "usd") {
        return currencyList;
      }
      return currencyList.filter((currency) => currency.btc_pair !== undefined);
    },
    [strategy]
  );

  const [availableCurrencies, setAvailableCurrencies] = useState<
    Currency[] | undefined
  >(() =>
    filterNonAvailableCurrencies(
      currenciesQuery.data?.sort(compareCurrencyRank)
    )
  );

  const setStrategyFromUnsavedStrategy = useCallback(() => {
    if (!unsavedStrategy) return;
    setTradingCurrenciesFromStrategy(unsavedStrategy);
    setStrategy(unsavedStrategy);
    setUpdateable(true);
    setRestorePrompt(false);
    forceUpdate();
  }, [
    unsavedStrategy,
    setStrategy,
    setUpdateable,
    setRestorePrompt,
    forceUpdate,
    setTradingCurrenciesFromStrategy,
  ]);

  const loadStrategy = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    if (!strategyId || !versionId) return;
    try {
      setLoadingStrategy(true);
      const locationState: any = location.state;
      if (locationState !== null) {
        if (
          locationState.strategy &&
          locationState.unsaved &&
          locationState.disablePrompt
        ) {
          setStrategyFromUnsavedStrategy();
          return;
        }
      }
      const response = await StrategyManagerService.blueprint.loadStrategy(
        token,
        strategyId,
        versionId
      );
      const fetched_strategy: StrategyVersion = response.payload!;
      if (locationState) {
        triggerToast(response.message, "success", null);
      }
      setStrategy(fetched_strategy);
      setSelectedCase(undefined);
      setSelectedCase(fetched_strategy.body.cases?.[0]);
      setTradingCurrenciesFromStrategy(fetched_strategy);
      localStorage.setItem("Strategy", JSON.stringify(fetched_strategy));
    } catch (error: any) {
      triggerToast(error, "error", null);
    } finally {
      setLoadingStrategy(false);
    }
  }, [
    strategyId,
    versionId,
    getAccessTokenSilently,
    triggerToast,
    setStrategy,
    location.state,
    setLoadingStrategy,
    setTradingCurrenciesFromStrategy,
    setStrategyFromUnsavedStrategy,
    setSelectedCase,
  ]);

  useQuery(["Strategy", versionId], loadStrategy, {
    cacheTime: 60 * 60,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!availableCurrencies) {
      setAvailableCurrencies(
        filterNonAvailableCurrencies(currenciesQuery.data)
      );
    }
    if (bodyRestored) {
      setBodyRestored(false);
    }
  }, [
    setAvailableCurrencies,
    availableCurrencies,
    filterNonAvailableCurrencies,
    bodyRestored,
    setBodyRestored,
    currenciesQuery.data,
  ]);

  const saveStrategyVersion = useCallback(
    async (overwrite_version_id?: string) => {
      if (!strategy) return;
      const token = await getAccessTokenSilently();
      if (!token) return;
      setUpdateLoading(true);
      try {
        setStrategy(strategy);
        const response = await StrategyManagerService.updateStrategy(
          token,
          strategy.strategy_id,
          strategy?.header,
          strategy?.body,
          overwrite_version_id
        );
        const updatedStrategy = response.payload;
        if (!updatedStrategy) return;
        navigate(
          `/strategy/${updatedStrategy.strategy_id}/${updatedStrategy.version_id}`
        );
        triggerToast(response.message, "success", null);
        setUpdateable(false);
      } catch (error: any) {
        triggerToast(error, "error", null);
      } finally {
        tryDeleteUnsavedStrategy();
        setUpdateLoading(false);
      }
    },
    [
      strategy,
      getAccessTokenSilently,
      triggerToast,
      setUpdateable,
      setStrategy,
      navigate,
    ]
  );

  const getStrategyCaseReplaceIndex = (
    cases: Case[],
    strategyCaseToBeUpdated: Case
  ) => {
    return cases.findIndex((strategyCase) => {
      if (strategyCase.name === strategyCaseToBeUpdated.name) {
        return true;
      }
      return false;
    });
  };

  const UpdateSelectedCase = useCallback(
    (updatedCase: Case) => {
      if (!strategy) return;
      const replaceIndex = getStrategyCaseReplaceIndex(
        strategy.body.cases,
        updatedCase
      );

      setSelectedCase(updatedCase);
      const _strategy = strategy;
      _strategy.body.cases[replaceIndex] = updatedCase;
      updateBody(_strategy);
      setTradingCurrenciesFromStrategy(_strategy);
      updateFixedPoolComposition(_strategy);
    },
    [
      strategy,
      updateBody,
      setTradingCurrenciesFromStrategy,
      updateFixedPoolComposition,
      setSelectedCase,
    ]
  );

  const renameCase = useCallback(
    (oldName: string, newName: string) => {
      if (!strategy) return;
      // Check if the new name already exists
      const existingCaseNames = strategy.body.cases.map((c) => c.name);
      if (existingCaseNames.includes(newName)) {
        alert("A case with this name already exists."); // Or handle this case as you see fit
        return;
      }

      // Find the index of the case to rename
      const caseIndex = strategy.body.cases.findIndex(
        (c) => c.name === oldName
      );
      if (caseIndex === -1) return; // Case not found, exit

      // Create a new strategy object with updated cases
      const updatedCases = strategy.body.cases.map((c, index) =>
        index === caseIndex ? { ...c, name: newName } : c
      );

      const updatedStrategy = {
        ...strategy,
        body: {
          ...strategy.body,
          cases: updatedCases,
        },
      };

      setStrategy(updatedStrategy);
      setUpdateable(!isEqual(strategy, updatedStrategy));
    },
    [strategy, setStrategy, setUpdateable]
  );

  const deepClone = useCallback((obj) => {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }

    if (obj instanceof Array) {
      let copy: any = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = deepClone(obj[i]);
      }
      return copy;
    }

    if (obj instanceof Object) {
      let copy = {};
      for (let attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = deepClone(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }, []);

  const copyCase = useCallback(
    (caseToCopy: Case) => {
      const caseCopy = deepClone(caseToCopy) as Case;
      caseCopy.name = generateRandomName();

      // Assuming `strategy` is the current strategy object you're working with
      if (!strategy) return;
      const _strategy = { ...strategy }; // Create a shallow copy of the strategy to trigger state update

      // Check if the newly generated name already exists (which should be unlikely with a good generateRandomName function)
      const existingCaseNames = _strategy.body.cases.map(
        (strategyCase) => strategyCase.name
      );
      if (existingCaseNames.includes(caseCopy.name)) {
        console.error(
          "Generated case name already exists. Please try copying again."
        );
        return;
      }

      // Add the copied case to the strategy's cases
      _strategy.body.cases.push(caseCopy);

      // Assuming you have a method to update the strategy state, e.g., setStrategy
      setStrategy(_strategy);

      // Optionally, select the newly copied case
      setSelectedCase(caseCopy);
    },
    [strategy, setStrategy, setSelectedCase, deepClone]
  );

  const caseNames = useMemo(() => {
    // this should update more often, but it doesn't
    if (!strategy) return [];
    return strategy.body.cases.map((strategyCase) => strategyCase.name);
  }, [strategy]);

  const { height } = useViewportSize();
  const upperRowRef = useRef<HTMLDivElement>(null);

  const [selectedCurrency, setSelectedCurrency] = useState<
    Currency | undefined
  >();

  useEffect(() => {
    if (availableCurrencies && strategy && !selectedCurrency) {
      const _curr =
        strategy?.included_currencies.length > 0
          ? availableCurrencies
              .filter((currency) =>
                strategy.included_currencies.includes(currency.currency_name)
              )
              .sort((a, b) => (a.market_cap < b.market_cap ? 1 : -1))[0] ||
            availableCurrencies[0]
          : availableCurrencies[0];
      setSelectedCurrency(_curr);
    }
  }, [selectedCurrency, strategy, availableCurrencies]);

  return (
    <SelectedCurrencyContext.Provider value={selectedCurrency}>
      <SetSelectedCurrencyContext.Provider value={setSelectedCurrency}>
        <SetSelectedCaseContext.Provider value={setSelectedCase}>
          <SelectedCaseContext.Provider value={selectedCase}>
            <CaseNamesContext.Provider value={caseNames}>
              <CurrencyContext.Provider value={currenciesQuery.data}>
                <IndicatorsContext.Provider value={indicatorsQuery.data}>
                  <IndicatorInfosContext.Provider
                    value={indicatorInfoQuery.data}
                  >
                    <AddCaseContext.Provider value={addCase}>
                      <DeleteCaseContext.Provider value={deleteCase}>
                        <UpdateSelectedCaseContext.Provider
                          value={UpdateSelectedCase}
                        >
                          <CopyCaseContext.Provider value={copyCase}>
                            <RenameCaseContext.Provider value={renameCase}>
                              <CriteriaTestResourceContext.Provider
                                value={criteriaTestResource}
                              >
                                <SetCriteriaTestResourceContext.Provider
                                  value={setCriteriaTestResource}
                                >
                                  {applicationSettings?.enableTutorial &&
                                    !restorePrompt && (
                                      <StrategyTutorial
                                        activeTheme={props.activeTheme}
                                      />
                                    )}
                                  {compact ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <label>Mobile not supported</label>
                                    </div>
                                  ) : (
                                    <>
                                      {loadingStrategy ? (
                                        <div className="strategy-loading-container">
                                          <NeotonLoader />
                                        </div>
                                      ) : (
                                        <>
                                          <Modal
                                            opened={restorePrompt}
                                            onClose={() =>
                                              setRestorePrompt(false)
                                            }
                                            hideCloseButton
                                            closeOnClickOutside={false}
                                          >
                                            <div className="prompt-container">
                                              <label>
                                                You have unsaved changes to this
                                                strategy. Do you wish to restore
                                                them?
                                              </label>

                                              <div className="prompt-buttons-container">
                                                <CommonButton
                                                  label="Yes, restore"
                                                  onClick={
                                                    setStrategyFromUnsavedStrategy
                                                  }
                                                  activeTheme={
                                                    props.activeTheme
                                                  }
                                                  borderTheme="green-accent"
                                                />
                                                <CommonButton
                                                  label="No, discard"
                                                  onClick={() => {
                                                    setRestorePrompt(false);
                                                    tryDeleteUnsavedStrategy();
                                                  }}
                                                  activeTheme={
                                                    props.activeTheme
                                                  }
                                                  borderTheme="red-accent"
                                                />
                                              </div>
                                            </div>
                                          </Modal>

                                          <div
                                            className="strategy-page-upper-row"
                                            ref={upperRowRef}
                                          >
                                            {availableCurrencies &&
                                              strategy && (
                                                <UpdateStrategyHeaderContext.Provider
                                                  value={updateHeader}
                                                >
                                                  <StrategyHeaderComponent
                                                    activeTheme={
                                                      props.activeTheme
                                                    }
                                                    tradingCurrencies={
                                                      tradingCurrencies
                                                    }
                                                    strategy={strategy}
                                                    saveStrategyVersion={
                                                      saveStrategyVersion
                                                    }
                                                    updateLoading={
                                                      updateLoading
                                                    }
                                                    updateable={updateable}
                                                    restoreStrategy={
                                                      restoreStrategy
                                                    }
                                                    addCase={addCase}
                                                    deleteCase={deleteCase}
                                                    availableCurrencies={
                                                      availableCurrencies
                                                    }
                                                  />
                                                </UpdateStrategyHeaderContext.Provider>
                                              )}
                                          </div>
                                          {strategy && availableCurrencies && (
                                            <div className="strategy-page-lower-row">
                                              <motion.div
                                                className="strategy-body-center-container"
                                                animate={{
                                                  height:
                                                    height -
                                                    (upperRowRef.current
                                                      ?.clientHeight ?? 200) -
                                                    10,
                                                }}
                                              >
                                                <StrategyCenter
                                                  compact={compact}
                                                  activeTheme={
                                                    props.activeTheme
                                                  }
                                                  strategy={strategy}
                                                  availableCurrencies={
                                                    availableCurrencies
                                                  }
                                                />
                                              </motion.div>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </SetCriteriaTestResourceContext.Provider>
                              </CriteriaTestResourceContext.Provider>
                            </RenameCaseContext.Provider>
                          </CopyCaseContext.Provider>
                        </UpdateSelectedCaseContext.Provider>
                      </DeleteCaseContext.Provider>
                    </AddCaseContext.Provider>
                  </IndicatorInfosContext.Provider>
                </IndicatorsContext.Provider>
              </CurrencyContext.Provider>
            </CaseNamesContext.Provider>
          </SelectedCaseContext.Provider>
        </SetSelectedCaseContext.Provider>
      </SetSelectedCurrencyContext.Provider>
    </SelectedCurrencyContext.Provider>
  );
}

export const SelectedCaseContext = createContext<Case | undefined>(undefined);

export const SetSelectedCaseContext = createContext<
  (selectedCase: Case | undefined) => void
>(() => {});

export const UpdateSelectedCaseContext = createContext<
  (updatedCase: Case) => void
>(() => {});

export const UpdateStrategyHeaderContext = createContext<
  (updatedHeader: Header) => void
>(() => {});

export const RenameCaseContext = createContext<
  (oldName: string, newName: string) => void
>(() => {});

export const CaseNamesContext = createContext<string[]>([]);

export const CopyCaseContext = createContext<(caseToCopy: Case) => void>(
  () => {}
);

export const AddCaseContext = createContext<
  (
    caseName: string,
    entry_criteria?: EntryCriteria,
    exit_criteria?: ExitCriteria,
    copiedCase?: Case
  ) => void
>(() => {});

export const DeleteCaseContext = createContext<(caseName: string) => void>(
  () => {}
);

export const CriteriaTestResourceContext = createContext<
  CriteriaTestResource | undefined
>(undefined);
export const SetCriteriaTestResourceContext = createContext<
  (criteriaTestResource: CriteriaTestResource | undefined) => void
>(() => {});

export interface CriteriaTestResource {
  candleSize: CandleSize;
  entryCriteria?: string;
  exitCriteria?: string;
}
