import { useAuth0 } from "@auth0/auth0-react";
import { Currency } from "../../interfaces/Currency";
import { useCallback, useContext, useState } from "react";
import {
  PricesOverviewRequestPayload,
  TraderType,
} from "../../pages/common/TradingDashboard";
import { getBlueprintService } from "../../utils/serviceUtil";
import { StrategyManagerService } from "../../services/StrategyManagerService";
import { useQuery } from "react-query";
import { SetSelectedCurrencyContext } from "../shared-context/HandleCurrencyContext";
import { CurrencyContext } from "../../shared-service-contexts/CurrencyContext";
import { CommonChartNavigation } from "../common/common-chart-navigation/CommonChartNavigation";

interface Props {
  activeTheme: string;
  candleSize: string;
  selectedCurrency: Currency | undefined;
  traderType?: TraderType;
  currencyPair?: string;
  handleNavigationChartClick: (
    candleSize: string,
    centerTimestamp: number | null
  ) => void;
  currentInterval: [number, number];
  onCurrencySelection?: (currency: Currency) => void;
  currencySelectionOverride?: Currency[];
  enable1DChartClick?: boolean;
  descriptionChild?: React.ReactNode;
}
export function ChartNavigation(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently } = useAuth0();
  const currencies = useContext(CurrencyContext);
  const setSelectedCurrency = useContext(SetSelectedCurrencyContext);
  const selectedCurrency = props.selectedCurrency ?? currencies?.[0];

  const minDate = 1577840400000; // 2020-01-01

  const fetchOverviewData = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token || !selectedCurrency) return;
    const blueprintService = props.traderType
      ? getBlueprintService(props.traderType)
      : StrategyManagerService.blueprint;
    const _payload: PricesOverviewRequestPayload = {
      start: minDate,
      stop: new Date().getTime(),
      currencies: [selectedCurrency.currency_name],
      candle_size: "1D",
    };

    const response = await blueprintService.getPricesOverview(token, _payload);
    return response.data;
  }, [
    selectedCurrency,
    props.traderType,
    getAccessTokenSilently,
    minDate,
  ]);

  const priceOverviewQuery = useQuery(
    ["priceOverview", selectedCurrency?.currency_name],
    fetchOverviewData,
    {
      cacheTime: 60 * 60 * 1000 * 24,
      staleTime: 60 * 60 * 1000 * 24,
      keepPreviousData: true,
    }
  );

  const [opened, setOpened] = useState(false);

  return (
    <div className="chart-navigation-container">
      {currencies && (
        <CommonChartNavigation
          setOpened={setOpened}
          activeTheme={props.activeTheme}
          candleSize={props.candleSize}
          currencySelection={props.currencySelectionOverride ?? currencies}
          currentInterval={props.currentInterval}
          handleNavigationChartClick={props.handleNavigationChartClick}
          enable1DChartClick={props.enable1DChartClick}
          onCurrencySelection={(currency) => {
            try {
              if (
                selectedCurrency?.currency_name === currency.currency_name
              )
                return;
              props.handleNavigationChartClick(
                props.candleSize,
                new Date().getTime()
              );
              props.onCurrencySelection
                ? props.onCurrencySelection(currency)
                : setSelectedCurrency(currency);
            } finally {
              setOpened(false);
            }
          }}
          descriptionChild={props.descriptionChild}
          opened={opened}
          priceData={priceOverviewQuery.data?.currencyPrices[0]}
          selectedCurrency={props.selectedCurrency}
        />
      )}
    </div>
  );
}
