import "./strategy-version-icon.scss";
import { motion } from "framer-motion";
import { Identicon } from "./Identicon";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { CurrencyVibranceContext } from "../../../App";
import { getTheme } from "../../../utils/themeUtil";
import { Divider, Menu } from "@mantine/core";
import { IoCodeSlash, IoCodeWorkingOutline, IoWallet } from "react-icons/io5";
import { IsBacktestingContext } from "../../../pages/backtester-page/BacktesterPage";
import { useNavigate } from "react-router-dom";
import { useClipboard } from "@mantine/hooks";
import { toTimestring } from "../../../utils/FormattingUtils";
import { VscHistory } from "react-icons/vsc";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import { CurrencyLogoArray } from "../common-currencies/CurrencyLogoArray";

interface Props {
  activeTheme: string;
  included_currencies: string[];
  strategy_id: string;
  version_id: string;
  name?: string;
  created_on?: number;
  size?: number;
  hideLabel?: boolean;
  disableMenu?: boolean;
  livetraderId?: string;
  menuPosition?: "left" | "right";
  compact?: boolean;
  primaryMenuButton?: React.ReactNode;
  secondaryMenuButton?: React.ReactNode;
  showCurrenciesAndName?: boolean;
  showNameLabel?: boolean;
}
export function StrategyVersionIcon(props: React.PropsWithChildren<Props>) {
  const currencies = useContext(CurrencyContext);
  const navigate = useNavigate();
  const isRunning = useContext(IsBacktestingContext);
  const size = props.size ?? 40;
  const getFallbackColor = useCallback(() => {
    const theme = getTheme(props.activeTheme);
    return theme.cardInteriorAlt;
  }, [props.activeTheme]);
  const fallbackColor = getFallbackColor();

  const currencyVibrances = useContext(CurrencyVibranceContext);


  const getColors = useCallback(() => {
    const fallbackColors = [fallbackColor];
    if (!currencyVibrances) return fallbackColors;
    if (!props.included_currencies) return fallbackColors;
    return props.included_currencies.map((currency) => {
      const vibrance = currencyVibrances[currency];
      if (!vibrance) return fallbackColor;
      const color = props.activeTheme === "light" ? vibrance.lightMuted :  vibrance.darkMuted;
      return color ?? fallbackColor;
    });
  }, [props.included_currencies, currencyVibrances, fallbackColor, props.activeTheme]);
  const colors: string[] = getColors();

  const hoverGradient = useMemo(() => {
    const previewColors = colors.slice(0, 2);
    if (previewColors.length < 2)
      return `linear-gradient(45deg,  ${previewColors.join(
        ","
      )}, ${fallbackColor},  ${previewColors.join(",")})`;
    return `linear-gradient(45deg,  ${previewColors[0]}, ${fallbackColor}, ${previewColors[1]})`;
  }, [colors, fallbackColor]);

  const [menuOpened, setMenuOpened] = useState(false);
  const versionIdClipboard = useClipboard({ timeout: 850 });

  return (
    <Menu
      onScroll={() => setMenuOpened(false)}
      withArrow
      opened={menuOpened}
      position={props.menuPosition ?? "right"}
      styles={{
        root: {
          alignSelf: "center",
        },
      }}
      control={
        <motion.div
          onClick={(e: any) => {
            if (props.disableMenu) return;
            e.stopPropagation();
            setMenuOpened(!menuOpened);
          }}
          whileHover={
            !props.disableMenu
              ? {
                  scale: props.showCurrenciesAndName ? 1 : 1.1,
                  filter: "grayscale(0%)",
                }
              : {}
          }
          whileTap={{ scale: 0.9 }}
          className={
            "strategy-version-icon-container" +
            (props.disableMenu ? " no-menu" : "")
          }
          initial={{ scale: 0, opacity: 0 }}
          animate={{
            scale: 1,
            opacity: props.disableMenu ? 0.5 : 1,
            filter:props.disableMenu ? "grayscale(50%)" : "grayscale(0%)",
            backgroundImage: props.showCurrenciesAndName ? undefined :  hoverGradient,
          }}
          style={{
            height: `${size}px`,
            width: `${size}px`,
            minWidth: props.showCurrenciesAndName ? "120px" : `${size}px`,
          }}
          exit={{ scale: 0 }}
        >
          <div
            className="identicon-container"
            style={{
              width: size,
              height: size,
              backgroundImage: hoverGradient,
            }}
          >
            <Identicon guid={props.version_id} size={size} />
          </div>
          {props.showCurrenciesAndName && props.showNameLabel && props.name && (
            <motion.label
              initial={{ opacity: 0, x: 25 }}
              animate={{ opacity: 0.7, x: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, delay: 0.3 }}
              className="strategy-version-name-label-relative"

            >
              {props.name?.length > 12 ? props.name?.slice(0, 12) + "..." : props.name}
            </motion.label>
          )}
          {props.showCurrenciesAndName && currencies && (
            <motion.div
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              exit={{ scaleX: 0 }}
              transition={{ duration: 0.2, delay: 0.1 }}
              className="strategy-version-icon-currency-array"
            >
              <CurrencyLogoArray
                size="xs"
                expandable={true}
                previewAmount={3}
                currencies={currencies.filter((c) =>
                  props.included_currencies.includes(c.currency_name)
                )}
              />
            </motion.div>
          )}
          {versionIdClipboard.copied && (
            <motion.label
              initial={{ opacity: 0, rotate: 0, x: 25, y: -5 }}
              animate={{ opacity: 0.8, rotate: 0, x: 35, y: -25 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="copied-label"
            >
              Copied to clipboard
            </motion.label>
          )}
          {!props.hideLabel && props.name && (
            <label
              style={{
                border: `1px solid ${fallbackColor}`,
              }}
              className="strategy-version-name-label"
            >
              {props.name}
            </label>
          )}
        </motion.div>
      }
      onClose={() => setMenuOpened(false)}
    >
      {props.name && (
        <Menu.Label>
          <label className="menu-version-name-label">{props.name}</label>
        </Menu.Label>
      )}
      {props.livetraderId && (
        <Menu.Item
          color="blue"
          icon={<IoWallet />}
          onClick={(e: any) => {
            e.stopPropagation();
            navigate(`/livetrader`, {
              state: { tradingAccountId: props.livetraderId },
            });
          }}
        >
          <label>Go to livetrader</label>
        </Menu.Item>
      )}

      {props.primaryMenuButton && props.primaryMenuButton}
      {props.primaryMenuButton && <Divider />}

      <Menu.Item
        disabled={isRunning}
        icon={<VscHistory />}
        onClick={(e: any) => {
          e.stopPropagation();
          navigate('/')
          navigate("/backtester", {
            state: {
              strategyId: props.strategy_id,
              versionId: props.version_id,
            },
          });
        }}
      >
        <label>Backtest version</label>
      </Menu.Item>
      <Menu.Item
        disabled={isRunning || props.compact}
        icon={<IoCodeSlash />}
        onClick={(e: any) => {
          e.stopPropagation();
          navigate(`/strategy/${props.strategy_id}/${props.version_id}`);
        }}
      >
        <label>Edit version</label>
      </Menu.Item>
      <Menu.Item
        onClick={(e: any) => {
          e.stopPropagation();
          versionIdClipboard.copy(props.version_id);
        }}
        icon={<IoCodeWorkingOutline />}
      >
        <label>Copy version ID</label>
      </Menu.Item>

      {props.secondaryMenuButton && <Divider />}
      {props.secondaryMenuButton && props.secondaryMenuButton}
      <Divider />
      {props.created_on ? (
        <Menu.Label>
          <label>
            Created: <strong>{toTimestring(props.created_on, "1h")}</strong>
          </label>
        </Menu.Label>
      ) : undefined}
      <Menu.Label>
        <label className="dimmed-label version-id">
          {props.version_id.slice(0, 28)}...
        </label>
      </Menu.Label>
    </Menu>
  );
}
