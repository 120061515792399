import "./case-control.scss";
import { Avatar, Popover, Switch, Tooltip } from "@mantine/core";
import { Currency } from "../../../interfaces/Currency";
import { Case } from "../../../interfaces/strategyInterfaces/Strategy";
import { motion } from "framer-motion";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { NewCurrencySelection } from "./NewCurrencySelection";
import { UpdateSelectedCaseContext } from "../../../pages/strategy-page/StrategyPage";
import { TierBadges } from "./TierBadges";
import { CriteriaOverview } from "./CriteriaOverview";
import { CaseExtras } from "./CaseExtras";
import { NewCriteriaEditor } from "./NewCriteriaEditor";
import { IndicatorsContext } from "../../../shared-service-contexts/IndicatorContext";
import { getCurrencyUrl } from "../../../utils/cdnUtil";
import useWindowDimensions from "../../../hooks/UseWIndowsDimensions";
import { StrategyPopupParentContainerRefContext } from "../../../pages/common/TradingDashboard";
interface Props {
  activeTheme: string;
  case: Case;
  currencies: Currency[] | undefined;
  readonly?: boolean;
}
export function CaseControl(props: React.PropsWithChildren<Props>) {
  const { height } = useWindowDimensions();
  const applicationIndicators = useContext(IndicatorsContext);
  const popupParentContainerRef = useContext(
    StrategyPopupParentContainerRefContext
  );
  const indicators = applicationIndicators?.indicators;
  const selectedCurrencies = props.currencies?.filter((currency) =>
    props.case.included_currencies.includes(currency.currency_name)
  );
  const updateCase = useContext(UpdateSelectedCaseContext);
  const [showCurrencySelection, setShowCurrencySelection] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [editingCriteria, setEditingCriteria] = useState<string | undefined>();
  const [editingCriteriaType, setEditingType] = useState<string | undefined>();
  const [editingCandleSize, setEditingCandleSize] = useState<
    string | undefined
  >();
  const AICurrencies = applicationIndicators?.ai_supported_currencies ?? [];

  const handleUpdateIncludedCurrencies = useCallback(
    (currencyName: string) => {
      const newIncludedCurrencies = props.case.included_currencies;
      // if currency is already included, remove it
      if (newIncludedCurrencies.includes(currencyName)) {
        const index = newIncludedCurrencies.indexOf(currencyName);
        newIncludedCurrencies.splice(index, 1);
      } else {
        // if currency is not included, add it
        newIncludedCurrencies.push(currencyName);
      }
      const _case = props.case;
      _case.included_currencies = newIncludedCurrencies;
      updateCase(_case);
    },
    [props.case, updateCase]
  );

  const toggleCase = useCallback(
    (toggle: boolean) => {
      const _case = props.case;
      _case.disabled = toggle;
      updateCase(_case);
    },
    [props.case, updateCase]
  );

  const handleSetEditMode = useCallback(
    (criteria?: string, type?: string, candleSize?: string) => {
      setShowCurrencySelection(false);
      setEditMode(!editMode);
      setEditingCriteria(criteria);
      setEditingType(type);
      setEditingCandleSize(candleSize);
    },
    [
      editMode,
      setEditMode,
      setEditingCriteria,
      setEditingType,
      setEditingCandleSize,
      setShowCurrencySelection,
    ]
  );

  const collectedCriterias = [
    ...props.case.entry_criteria.candle_size_1D,
    ...props.case.entry_criteria.candle_size_1h,
    ...props.case.entry_criteria.candle_size_5m,
    ...props.case.exit_criteria.candle_size_1D,
    ...props.case.exit_criteria.candle_size_1h,
    ...props.case.exit_criteria.candle_size_5m,
  ];

  const hasIndicators = useCallback(
    (indicatorKeys: string[], criterias: string[]) => {
      const hasIndicator = indicatorKeys.some((key) =>
        criterias.some((str) => str.includes(key))
      );
      return hasIndicator;
    },
    []
  );

  const hasAIInCriterias = indicators
    ? hasIndicators(
        Object.keys(applicationIndicators.ai_translations),
        collectedCriterias
      )
    : false;

  const allowedCaseCurrencies = useMemo(() => {
    if (hasAIInCriterias) {
      return props.currencies?.filter((currency) =>
        AICurrencies.includes(currency.currency_name)
      );
    }
    return props.currencies;
  }, [props.currencies, AICurrencies, hasAIInCriterias]);

  const handleReplaceCriteria = useCallback(
    (newCriteria: string) => {
      if (!editingCriteria || !editingCriteriaType || !editingCandleSize)
        return;
      const _case = props.case;
      const index =
        _case[`${editingCriteriaType}_criteria`][
          `candle_size_${editingCandleSize}`
        ].indexOf(editingCriteria);
      _case[`${editingCriteriaType}_criteria`][
        `candle_size_${editingCandleSize}`
      ][index] = newCriteria;
      updateCase(_case);
      setEditMode(false);
      setEditingCandleSize(undefined);
      setEditingCriteria(undefined);
    },
    [
      editingCandleSize,
      editingCriteria,
      editingCriteriaType,
      props.case,
      updateCase,
      setEditMode,
      setEditingCandleSize,
      setEditingCriteria,
    ]
  );

  const handleAddCriteria = useCallback(
    (newCriteria: string) => {
      if (!editingCriteriaType || !editingCandleSize) return;
      const _case = props.case;
      _case[`${editingCriteriaType}_criteria`][
        `candle_size_${editingCandleSize}`
      ].push(newCriteria);
      updateCase(_case);
      setEditMode(false);
      setEditingCandleSize(undefined);
      setEditingCriteria(undefined);
    },
    [
      editingCandleSize,
      editingCriteriaType,
      props.case,
      updateCase,
      setEditMode,
      setEditingCandleSize,
      setEditingCriteria,
    ]
  );

  const handleNewCriteriaClick = useCallback(
    (candleSize: string, criteriaType: string) => {
      handleSetEditMode(undefined, criteriaType, candleSize);
    },
    [handleSetEditMode]
  );

  const handleDeleteCriteria = useCallback(
    (candleSize: string, criteriaType: string, index: number) => {
      const _case = props.case;

      _case[`${criteriaType}_criteria`][`candle_size_${candleSize}`].splice(
        index,
        1
      );
      updateCase(_case);
    },
    [props.case, updateCase]
  );

  const caseControlHeight = useMemo(() => {
    if (popupParentContainerRef?.current) {
      return popupParentContainerRef.current.clientHeight * 0.7;
    }
    return height * 0.6;
  }, [popupParentContainerRef, height]);

  return (
    <SetEditModeContext.Provider value={handleSetEditMode}>
      <AICurrenciesContext.Provider value={AICurrencies}>
        <div className="case-control-container">
          {!editMode && (
            <div className="case-control-header">
              <TierBadges collectedCriterias={collectedCriterias} noMargin />
              {props.case.disabled && (
                <motion.label
                  initial={{ opacity: 0, x: 10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                >
                  <Tooltip label={"This case will not be executed"}>
                    Disabled
                  </Tooltip>
                </motion.label>
              )}

              <label>{props.case.name}</label>

              <Switch
                disabled={props.readonly}
                onChange={() => toggleCase(!props.case.disabled)}
                checked={!props.case.disabled}
                color="cyan"
              />
            </div>
          )}
          <motion.div
            className="case-control-scrollable-body"
            animate={{
              height: caseControlHeight,
            }}
          >
            <Popover
              opened={showCurrencySelection}
              onClose={() => setShowCurrencySelection(false)}
              position="bottom"
              withArrow
              width={400}
              shadow="xl"
              classNames={{
                inner: "notifications-popover-inner",
                target: "notifications-popover-target",
                arrow: "notifications-popover-arrow",
                body: "notifications-popover-body",
                root: "notifications-popover-root",
                wrapper: "notifications-popover-wrapper",
                popover: "notifications-popover-popover",
              }}
              target={
                <>
                  {props.currencies && !editMode && (
                    <motion.div
                      initial={{ opacity: 0, width: "100%" }}
                      animate={{ opacity: 1, width: "100%" }}
                      transition={{ duration: 0.2, delay: 0.2 }}
                      className="target-currencies-container"
                    >
                      <label className="dimmed-label">Target currencies:</label>
                      <div
                        className={
                          selectedCurrencies && selectedCurrencies.length > 0
                            ? "target-currencies-selection-container"
                            : "target-currencies-selection-container-empty"
                        }
                        onClick={() =>
                          !props.readonly &&
                          setShowCurrencySelection(!showCurrencySelection)
                        }
                      >
                        {selectedCurrencies && selectedCurrencies.length > 0 ? (
                          <>
                            {selectedCurrencies.map((currency) => {
                              return (
                                <Avatar
                                  src={getCurrencyUrl(currency)}
                                  size={26}
                                  key={currency.currency_name}
                                  radius="lg"
                                />
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <label>Pick target currencies</label>
                          </>
                        )}
                      </div>
                    </motion.div>
                  )}
                </>
              }
            >
              {showCurrencySelection && props.currencies && (
                <NewCurrencySelection
                  allowedCurrencies={
                    allowedCaseCurrencies?.map(
                      (currency) => currency.currency_name
                    ) ?? []
                  }
                  includedCurrencies={props.case.included_currencies}
                  currencies={props.currencies}
                  handleUpdateIncludedCurrencies={
                    handleUpdateIncludedCurrencies
                  }
                />
              )}
            </Popover>

            {!editMode ? (
              <>
                <CaseExtras
                  activeTheme={props.activeTheme}
                  readonly={props.readonly}
                  case={props.case}
                />
                <div className="case-logic-container">
                  <label
                    className="dimmed-label"
                    style={{
                      alignSelf: "center",
                    }}
                  >
                    Trading criterias
                  </label>
                  <CriteriaOverview
                    readonly={props.readonly}
                    handleDeleteCriteria={handleDeleteCriteria}
                    handleNewCriteriaOverview={handleNewCriteriaClick}
                    activeTheme={props.activeTheme}
                    criteria={props.case.entry_criteria}
                    criteriaType="entry"
                  />
                  <CriteriaOverview
                    readonly={props.readonly}
                    handleDeleteCriteria={handleDeleteCriteria}
                    handleNewCriteriaOverview={handleNewCriteriaClick}
                    activeTheme={props.activeTheme}
                    criteria={props.case.exit_criteria}
                    criteriaType="exit"
                  />
                </div>
              </>
            ) : (
              <>
                {editingCandleSize && (
                  <NewCriteriaEditor
                    activeTheme={props.activeTheme}
                    criteria={editingCriteria}
                    candleSize={editingCandleSize}
                    criteriaType={editingCriteriaType ?? "entry"}
                    handleReplaceCriteria={handleReplaceCriteria}
                    handleAddCriteria={handleAddCriteria}
                    tradingCurrencies={
                      selectedCurrencies?.map(
                        (currency) => currency.currency_name
                      ) ?? []
                    }
                    removeCurrency={handleUpdateIncludedCurrencies}
                  />
                )}
              </>
            )}
          </motion.div>
        </div>
      </AICurrenciesContext.Provider>
    </SetEditModeContext.Provider>
  );
}

export const SetEditModeContext = createContext<
  (criteria?: string, type?: string, candleSize?: string) => void
>(() => {});
export const AICurrenciesContext = createContext<string[]>([]);
