import { createContext } from "react";
import { Currency } from "../../interfaces/Currency";

export const SetSelectedCurrencyContext = createContext<
  (currency: Currency) => void
>(() => {});

export const SelectedCurrencyContext = createContext<Currency | undefined>(
  undefined
);
