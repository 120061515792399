import {
  Line,
  LineChart,
  ReferenceArea,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { Order } from "../../../../interfaces/backtester/BacktestTradingReport";
import { CurrencyPriceData } from "../../../../interfaces/PricesOverview";

import {
  getPriceDecimalCount,
  toUTCTimestring,
} from "../../../../utils/FormattingUtils";
import { getTheme } from "../../../../utils/themeUtil";
import { ReactNode, memo, useMemo, useState } from "react";

type Props = {
  activeTheme: string;
  candleSize: string;
  priceData: CurrencyPriceData;
  chartContainerElement: HTMLDivElement | null | undefined;
  buyOrder: Order | undefined;
  sellOrder: Order | undefined;
};

export const OrderInspectChart: React.FC<Props> = (props) => {
  const theme = getTheme(props.activeTheme);
  const [enableAnnotation, setEnableAnnotation] = useState(false);

  const decimalCount = useMemo(() => {
    return props.priceData ? getPriceDecimalCount(props.priceData.prices) : 2;
  }, [props.priceData]);

  const dataset = useMemo(() => {
    const _dataset: any[] = [];
    for (let i = 0; i < props.priceData.prices.length; i++) {
      const timestamp = props.priceData.timestamps[i];
      const timeString = toUTCTimestring(timestamp, props.candleSize);
      const price = props.priceData.prices[i];
      _dataset.push({
        x: timeString,
        timestamp: timestamp,
        price: price,
      });
    }
    return _dataset;
  }, [props.priceData, props.candleSize]);

  const axisYDomain = useMemo(() => {
    if (!dataset) return undefined;
    const prices = dataset.map((entry) => entry.price);
    const min = Math.min(...prices);
    const max = Math.max(...prices);

    const padding = (max - min) * 0.1;
    return [min - padding, max + padding];
  }, [dataset]);

  const buyOrderDot = useMemo(() => {
    if (!props.buyOrder) return;
    const xValue = toUTCTimestring(
      parseInt(
        props.buyOrder.timestamp_references[`candle_size_${props.candleSize}`]
      ),
      props.candleSize
    );
    return (
      <>
        <ReferenceLine
          x={xValue}
          stroke={theme.buyOrderStroke}
          strokeWidth={2}
          strokeDasharray="6 6"
        />
        <ReferenceDot
          x={xValue}
          y={props.buyOrder.buy_price}
          strokeWidth={2}
          r={4}
          fill={theme.buyOrderStroke}
          stroke={theme.buyOrderStrokeHover}
        />
      </>
    );
  }, [props.buyOrder, theme, props.candleSize]);

  const sellOrderDot = useMemo(() => {
    if (!props.sellOrder) return;
    const xValue = toUTCTimestring(
      parseInt(
        props.sellOrder.timestamp_references[`candle_size_${props.candleSize}`]
      ),
      props.candleSize
    );
    return (
      <>
        <ReferenceLine
          x={xValue}
          stroke={theme.sellOrderStroke}
          strokeWidth={2}
          strokeDasharray="6 6"
        />
        <ReferenceDot
          x={xValue}
          y={props.sellOrder.sell_price}
          strokeWidth={2}
          r={4}
          fill={theme.sellOrderStroke}
          stroke={theme.sellOrderStrokeHover}
        />
      </>
    );
  }, [props.sellOrder, theme, props.candleSize]);

  const tooltipOrderLabels = useMemo(() => {
    const labelMap: { [key: string]: ReactNode } = {};
    // buy order
    const buyOrdertimestampKey = `candle_size_${props.candleSize}`;
    const buyOrdertimestamp =
      props.buyOrder?.timestamp_references[buyOrdertimestampKey];
    const buyOrdertimeString = toUTCTimestring(
      buyOrdertimestamp,
      props.candleSize
    );
    const detailLabel = (
      <div key={buyOrdertimeString} className="order-tooltip-labels">
        <label className={`order-tooltip-label buy`}>buy</label>
        <label>
          <strong>{props.buyOrder?.buy_price.toFixed(decimalCount)} $</strong>
        </label>
      </div>
    );
    labelMap[buyOrdertimeString] = detailLabel;

    // sell order
    if (props.sellOrder && props.sellOrder.sell_price) {
      const sellOrdertimestampKey = `candle_size_${props.candleSize}`;
      const sellOrdertimestamp =
        props.sellOrder.timestamp_references[sellOrdertimestampKey];
      const sellOrdertimeString = toUTCTimestring(
        sellOrdertimestamp,
        props.candleSize
      );
      const detailLabel = (
        <div key={sellOrdertimeString} className="order-tooltip-labels">
          <label className={`order-tooltip-label sell`}>sell</label>
          <label>
            <strong>
              {props.sellOrder.sell_price.toFixed(decimalCount)} $
            </strong>
          </label>
        </div>
      );
      labelMap[sellOrdertimeString] = detailLabel;
    }

    return labelMap;
  }, [props.buyOrder, props.sellOrder, props.candleSize]);

  const CustomTooltip = memo(
    ({ active, payload, label }: TooltipProps<number, string>) => {
      if (active && label && payload) {
        return (
          <div className="custom-tooltip">
            <div className="price-and-date-container">
              <label>{label}</label>
              <label
                color={"dimmed"}
                style={{ fontFamily: "IBMPlexMono-Light" }}
              >
                <strong>{payload?.[0]?.value?.toFixed(decimalCount)} $</strong>
              </label>
            </div>
            {tooltipOrderLabels?.[label]}
          </div>
        );
      }

      return null;
    }
  );

  const holdingArea = useMemo(() => {
    if (!props.buyOrder || !props.sellOrder || !props.sellOrder.sell_price)
      return;
    const buyOrdertimestampKey = `candle_size_${props.candleSize}`;
    const buyOrdertimestamp =
      props.buyOrder.timestamp_references[buyOrdertimestampKey];
    const sellOrdertimestampKey = `candle_size_${props.candleSize}`;
    const sellOrdertimestamp =
      props.sellOrder.timestamp_references[sellOrdertimestampKey];
    const buyOrdertimeString = toUTCTimestring(
      buyOrdertimestamp,
      props.candleSize
    );
    const sellOrdertimeString = toUTCTimestring(
      sellOrdertimestamp,
      props.candleSize
    );
    const holdingAreaFill =
      props.buyOrder.buy_price < props.sellOrder.sell_price
        ? theme.buyOrderAreaY
        : theme.sellOrderAreaY;
    return (
      <ReferenceArea
        x1={buyOrdertimeString}
        x2={sellOrdertimeString}
        y1={props.buyOrder.buy_price}
        y2={props.sellOrder.sell_price}
        fill={holdingAreaFill}
        fillOpacity={1}
      />
    );
  }, [props.buyOrder, props.sellOrder, props.candleSize, theme]);

  return (
    <ResponsiveContainer width="99%" height="99%">
      <LineChart width={300} height={200} data={dataset}>
        <Tooltip content={<CustomTooltip />} />
        <Line
          onAnimationEnd={() => setEnableAnnotation(true)}
          type="monotone"
          dataKey="price"
          stroke={theme.text}
          strokeWidth={2}
          
          activeDot={{ r: 3 }}
          dot={{ r: 0 }}
        />
        <XAxis dataKey="x" hide />
        {axisYDomain && (
          <YAxis domain={[axisYDomain[0], axisYDomain[1]]} hide />
        )}
        {enableAnnotation && buyOrderDot}
        {enableAnnotation && sellOrderDot}
        {enableAnnotation && holdingArea}
      </LineChart>
    </ResponsiveContainer>
  );
};
