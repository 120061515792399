import { motion } from "framer-motion";
import Lottie from "react-lottie";
import {
  Case,
  StrategyVersion,
} from "../../../interfaces/strategyInterfaces/Strategy";
import {
  CandleSizeContext,
  FocusedOrderEntryContext,
  GetSessionIntervalContext,
  GetWindowedIntervalContext,
  SetCandleSizeContext,
  SetWindowedIntervalContext,
  TraderType,
} from "../../../pages/common/TradingDashboard";
import { StrategyBodyPopup } from "../strategy/StrategyBodyPopup";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMediaQuery } from "@mantine/hooks";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import { Divider, Loader } from "@mantine/core";
import {
  CancelBacktestContext,
  IsBacktestingContext,
} from "../../../pages/backtester-page/BacktesterPage";
import { ChartSettings } from "../../../interfaces/chartIndicators/ChartSettings";
import { Currency } from "../../../interfaces/Currency";
import { ChartControls } from "../../strategy-body/ChartControls";
import { persistChartSettings } from "../../../utils/CachedDataUtil";
import { IoMdCodeDownload } from "react-icons/io";
import backtestRunningIconLight from "../../Animations/backtest-running-light.json";
import backtestRunningIcon from "../../Animations/backtest-running.json";
import { CommonCandleSizeControl } from "../common-candle-size-control/CommonCandleSizeControl";
import { CommonChartNavigation } from "../common-chart-navigation/CommonChartNavigation";
import { getBlueprintService } from "../../../utils/serviceUtil";
import { useQuery } from "react-query";
import {
  CurrencyPriceData,
  PricesOverview,
} from "../../../interfaces/PricesOverview";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getAllowedTimeInterval,
  increment5Min,
  incrementHour,
} from "../../../utils/CandleCountUtil";
import { Order } from "../../../interfaces/backtester/BacktestTradingReport";

interface Props {
  activeTheme: string;
  attachedStrategy: StrategyVersion | undefined;
  traderType: TraderType;
  strategyLoading: boolean;
  reportLoading?: boolean;
  focusedCurrency: Currency | undefined;
  chartSettings: ChartSettings | undefined;
  listChildToggled: boolean;
  orders: Order[] | undefined;
  setFocusedCurrency: (currency: Currency | undefined) => void;
  toggleListChild: () => void;
  setChartSettings: (value: React.SetStateAction<ChartSettings>) => void;
  getCurrencyPreviewName: () => string;
}
export function CommonOverviewHeader(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently } = useAuth0();
  const currencies = useContext(CurrencyContext);
  const candleSize = useContext(CandleSizeContext);
  const setCandleSize = useContext(SetCandleSizeContext);
  const windowedInterval = useContext(GetWindowedIntervalContext);
  const setWindowedInterval = useContext(SetWindowedIntervalContext);
  const sessionInterval = useContext(GetSessionIntervalContext);
  const focusedOrderEntry = useContext(FocusedOrderEntryContext);
  // Backtester specific context
  const isRunning = useContext(IsBacktestingContext);
  const cancelling = useContext(CancelBacktestContext);
  const entityTerm =
    props.traderType.traderType === "livetrader" ? "Account" : "Strategy";
  const entityTermPlural =
    props.traderType.traderType === "livetrader" ? "Accounts" : "Strategies";
  const [currencySelectionOpened, setCurrencySelectionOpened] = useState(false);
  const [casePopupOpened, setCasePopupOpened] = useState(false);
  const [selectedCase, setSelectedCase] = useState<Case | undefined>();
  const [casePopupMode, setCasePopupMode] = useState<"list" | "view">("list");

  const minDate = useMemo(() => new Date(2020, 0, 1), []);
  const now = useMemo(() => new Date(), []);
  const maxDate = useMemo(() => {
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      0,
      0
    );
  }, [now]);

  const fetchPreviewCurrencyData = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    const previewName =
      props.focusedCurrency?.currency_name ?? props.getCurrencyPreviewName();
    const blueprintService = getBlueprintService(props.traderType);
    const response = await blueprintService.getPricesOverview(token, {
      currencies: [previewName],
      start: minDate.getTime(),
      stop: maxDate.getTime(),
      candle_size: "1D",
    });
    return response.data as PricesOverview;
  }, [
    getAccessTokenSilently,
    props.focusedCurrency,
    props.getCurrencyPreviewName,
    minDate,
    maxDate,
  ]);

  const previewCurrencyDataQuery = useQuery(
    ["chartNavigationPreviewData", props.focusedCurrency],
    fetchPreviewCurrencyData,
    { cacheTime: 120000, staleTime: 0, keepPreviousData: true }
  );

  const previewPriceData: CurrencyPriceData | undefined = useMemo(() => {
    if (!previewCurrencyDataQuery.data) {
      return undefined;
    }
    return previewCurrencyDataQuery.data.currencyPrices[0];
  }, [previewCurrencyDataQuery.data]);

  useEffect(() => {
    if (!props.attachedStrategy && selectedCase) {
      setSelectedCase(undefined);
    }
    if (props.attachedStrategy && !selectedCase) {
      setSelectedCase(props.attachedStrategy.body.cases[0]);
    }
  }, [selectedCase, props.attachedStrategy, setSelectedCase]);

  const availableCurrencies =
    currencies &&
    props.attachedStrategy &&
    props.attachedStrategy.included_currencies
      ? currencies.filter((currency) =>
          props.attachedStrategy?.included_currencies.includes(
            currency.currency_name
          )
        )
      : undefined;
  const enableStrategyPopup = useMediaQuery("(min-width: 1135px)");

  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData:
      props.activeTheme === "light"
        ? backtestRunningIconLight
        : backtestRunningIcon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  };
  const strategyPopupParentContainerRef = useRef<HTMLDivElement>(null);
  const handleChartNavigation = useCallback(
    (_candleSize: string, centerTimestamp: number | null) => {
      if (_candleSize === "1D") return;

      const interval = getAllowedTimeInterval(
        new Date().getTime(),
        700,
        _candleSize,
        centerTimestamp ?? sessionInterval?.stopTimestamp ?? 0
      );
      const sessionStart =
        (sessionInterval?.startTimestamp ?? 0) +
        (_candleSize === "1h" ? incrementHour() * 24 : increment5Min() * 24);
      const _windowedInterval = {
        startTimestamp:
          sessionStart && interval[0] < sessionStart
            ? sessionStart
            : interval[0],
        stopTimestamp: interval[1],
      };
      setWindowedInterval(_windowedInterval);
    },
    [sessionInterval, setWindowedInterval]
  );

  return (
    <>
      <div className="common-overview-header-outer-container">
        <div className="common-overview-header-container">
          {props.reportLoading ? (
            <Loader color="cyan" />
          ) : (
            <>
              {isRunning ? (
                <motion.label
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                  exit={{ opacity: 0 }}
                  className="backtest-running-label-container"
                >
                  <div className="spinning-icon-container">
                    <Lottie options={animationOptions} />
                  </div>
                  {cancelling ? "Cancelling..." : "Backtesting..."}
                </motion.label>
              ) : (
                <motion.label
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                  exit={{ opacity: 0 }}
                  onClick={props.toggleListChild}
                  className={
                    "overview-header-anchor-button" +
                    (props.listChildToggled ? " toggled" : "")
                  }
                >
                  <IoMdCodeDownload
                    className={
                      "code-icon" + (props.listChildToggled ? " toggled" : "")
                    }
                    size={25}
                  />
                  {entityTermPlural}
                </motion.label>
              )}
            </>
          )}

          <Divider orientation="vertical" />

          {props.attachedStrategy ? (
            <div
              className="attached-strategy-container"
              ref={strategyPopupParentContainerRef}
            >
              {enableStrategyPopup && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                >
                  <StrategyBodyPopup
                    showExtraControls
                    //compact
                    readonly
                    hideNewCaseButton
                    activeTheme={props.activeTheme}
                    opened={casePopupOpened}
                    onClose={() => {
                      if (props.listChildToggled) {
                        setCasePopupOpened(false);
                      }
                      return;
                    }}
                    strategy={props.attachedStrategy}
                    goToCase={() => setCasePopupMode("view")}
                    popupMode={casePopupMode}
                    selectCase={(_case) => setSelectedCase(_case)}
                    mainClick={() => {
                      setCasePopupMode(selectedCase ? "view" : "list");
                      setCasePopupOpened(
                        casePopupMode !== "view" ? true : !casePopupOpened
                      );
                    }}
                    listCasesClick={() => {
                      setCasePopupMode("list");
                      setCasePopupOpened(
                        casePopupMode !== "list" ? true : !casePopupOpened
                      );
                    }}
                    handleClose={() => {
                      setCasePopupOpened(false);
                    }}
                    availableCurrencies={availableCurrencies}
                    handleDelete={() => setCasePopupOpened(false)} // TODO: implement delete case
                    selectedCase={selectedCase}
                  />
                </motion.div>
              )}
              <motion.div
                initial={{ opacity: 0, x: -350 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                layout
                className="chart-controls-container"
              >
                <div className="chart-navigation-container">
                  <CommonChartNavigation
                    setOpened={setCurrencySelectionOpened}
                    activeTheme={props.activeTheme}
                    candleSize={candleSize}
                    currencySelection={availableCurrencies ?? []}
                    currentInterval={[
                      windowedInterval?.startTimestamp ??
                        sessionInterval?.startTimestamp ??
                        0,
                      windowedInterval?.stopTimestamp ??
                        sessionInterval?.stopTimestamp ??
                        0,
                    ]}
                    handleNavigationChartClick={handleChartNavigation}
                    onCurrencySelection={(currency) => {
                      if (props.listChildToggled) props.toggleListChild();
                      props.setFocusedCurrency(currency);
                      setCurrencySelectionOpened(false);
                    }}
                    onCurrencyClear={() => {
                      props.setFocusedCurrency(undefined);
                    }}
                    opened={currencySelectionOpened}
                    priceData={previewPriceData}
                    selectedCurrency={props.focusedCurrency}
                    sessionInterval={
                      sessionInterval
                        ? [
                            sessionInterval.startTimestamp,
                            sessionInterval.stopTimestamp,
                          ]
                        : undefined
                    }
                  />
                </div>

                <CommonCandleSizeControl
                  selectedCandleSize={candleSize}
                  onClick={(candleSize) => {
                    let timestamp = null;
                    if (focusedOrderEntry) {
                      const sellOrder = props.orders?.find(
                        (order) => order.id === focusedOrderEntry.orderIds[1]
                      );
                      const buyOrder = props.orders?.find(
                        (order) => order.id === focusedOrderEntry.orderIds[0]
                      );
                      const timestampKey = `candle_size_${candleSize}`;
                      const diff = sellOrder?.timestamp_references[timestampKey] - buyOrder?.timestamp_references[timestampKey];
                      timestamp = buyOrder?.timestamp_references[timestampKey] + diff / 2;
                    }
                    handleChartNavigation(candleSize, timestamp);
                    setCandleSize(candleSize);
                  }}
                />

                {props.focusedCurrency &&
                  props.chartSettings &&
                  previewPriceData && (
                    <motion.div layout className="chart-controls-button">
                      <ChartControls
                        forceUpdate={() => {}}
                        persistChartSettingsInLocalStorage={
                          persistChartSettings
                        }
                        activeTheme={props.activeTheme}
                        chartSettings={props.chartSettings!}
                        setChartSettings={props.setChartSettings}
                      />
                    </motion.div>
                  )}
              </motion.div>
            </div>
          ) : (
            <>
              {props.strategyLoading || props.reportLoading ? (
                <label>Loading...</label>
              ) : (
                <label className="dimmed-label">No {entityTerm} attached</label>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
