import { Currency } from "../../interfaces/Currency";
import { Case, StrategyVersion } from "../../interfaces/strategyInterfaces/Strategy";
import { PublicHeaderEditor } from "./PublicHeaderEditor";
import { StrategyControls } from "./StrategyControls";
import "./strategy-header-component.scss";

interface Props {
  activeTheme: string;
  strategy: StrategyVersion;
  tradingCurrencies: Currency[];
  updateable: boolean;
  updateLoading: boolean;
  saveStrategyVersion: (overwrite_version_id?: string) => Promise<void>;
  restoreStrategy: () => void;
  addCase: (caseName: string) => void;
  deleteCase: (caseName: string) => void;
  availableCurrencies: Currency[];
}
export function StrategyHeaderComponent(props: React.PropsWithChildren<Props>) {
  return (
    <div className="strategy-header-component-container">
      <div className="strategy-header-component">
        <div className="strategy-header-card public-header">
          <PublicHeaderEditor
            activeTheme={props.activeTheme}
            strategy={props.strategy}
            tradingCurrencies={props.tradingCurrencies}
          />
        </div>
        <div className="strategy-header-card">
          <StrategyControls
            activeTheme={props.activeTheme}
            updateable={props.updateable}
            updateLoading={props.updateLoading}
            tradingCurrencies={props.tradingCurrencies}
            updateStrategy={props.saveStrategyVersion}
            strategy={props.strategy}
            restoreStrategy={props.restoreStrategy}
            addCase={props.addCase}
            deleteCase={props.deleteCase}
            availableCurrencies={props.availableCurrencies}
          />
        </div>
      </div>
    </div>
  );
}
