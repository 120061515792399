import axios, { AxiosInstance } from "axios";
import {
  LivetraderReport,
  LivetraderUserLogItem,
} from "../interfaces/livetrader/LivewalletReport";
import { LivetradersOverview } from "../interfaces/livetrader/LiveWalletTradingAccounts";
import { CustomResponse } from "../interfaces/Response";
import BlueprintService from "./BlueprintService";
import { createOptions } from "./CreateOptions";
import {
  ApiPermissionResult,
  CurrenciesSupport,
  ExchangeFees,
  ExchangeSupport,
  PlatformCredentialsInput,
  SellHoldingResult,
  TradingAccountWalletTest,
  TradingAccounts,
  TradingStrategySlotInput,
} from "../interfaces/common-trading/TradingAccount";
import { WalletConfigurationInput } from "../components/livewallets-selection/TradingAccountConfigurator";
import { Order } from "../interfaces/backtester/BacktestTradingReport";

const http = axios.create({
  baseURL: process.env.REACT_APP_LIVETRADER_API,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

class LivetraderServiceClass {
  public axiosInstance: AxiosInstance;
  public blueprint: BlueprintService;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    this.blueprint = new BlueprintService(axiosInstance);
  }

  public getLivetradersOverview = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<LivetradersOverview>>(
      `/livetraders-overview`,
      options
    );
  };

  public getLivewallet = async (token: string, livewalletId: string) => {
    const options = createOptions(token);

    return await http.get<CustomResponse<LivetraderReport>>(
      `/livewallets/${livewalletId}`,
      options
    );
  };

  public getTradingAccounts = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<TradingAccounts>>(
      `/trading-accounts`,
      options
    );
  };

  public deleteTradingAccount = async (token: string, accountId: string) => {
    const options = createOptions(token);
    return await http.delete<CustomResponse<TradingAccounts>>(
      `/trading-account/${accountId}`,
      options
    );
  };

  public initTradingAccount = async (token: string) => {
    const options = createOptions(token);
    return await http.post<CustomResponse<TradingAccounts>>(
      `/trading-account/init`,
      {},
      options
    );
  };

  public getSupportedExchanges = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<ExchangeSupport>>(
      `/supported-exchanges`,
      options
    );
  };

  public getExchangesFees = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<ExchangeFees>>(
      `/exchange-fees`,
      options
    );
  };

  public updateTradingAccount = async (
    token: string,
    tradingAccountId: string,
    updateQuery: TradingAccountUpdateQuery
  ) => {
    const options = createOptions(token);

    return await http.post<CustomResponse<TradingAccounts>>(
      `/trading-account/update`,
      {
        trading_account_id: tradingAccountId,
        update_query: updateQuery,
      },
      options
    );
  };

  public getSupportedCurrencies = async (
    token: string,
    strategyId: string,
    versionId: string,
    exchangeId: string
  ) => {
    const options = createOptions(token);
    const body = {
      strategy_id: strategyId,
      version_id: versionId,
      exchange_id: exchangeId,
    };
    return await http.post<CustomResponse<CurrenciesSupport>>(
      `/trading-account/currency-support`,
      body,
      options
    );
  };

  public testApiPermissions = async (
    token: string,
    tradingAccountId: string
  ) => {
    const options = createOptions(token);
    const body = {
      trading_account_id: tradingAccountId,
    };
    return await http.post<CustomResponse<ApiPermissionResult>>(
      `/trading-account/api-permissions`,
      body,
      options
    );
  };

  public testWallet = async (token: string, tradingAccountId: string) => {
    const options = createOptions(token);
    const body = {
      trading_account_id: tradingAccountId,
    };
    return await http.post<CustomResponse<TradingAccountWalletTest>>(
      `/trading-account/test`,
      body,
      options
    );
  };

  public toggleTradingAccountActive = async (
    token: string,
    tradingAccountId: string,
    activate: boolean
  ) => {
    const options = createOptions(token);
    const body = {
      trading_account_id: tradingAccountId,
      activate: activate,
    };
    return await http.post<CustomResponse<TradingAccounts>>(
      `/trading-account/toggle`,
      body,
      options
    );
  };

  public getLivetraderPageOverview = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<LivetraderPageOverviewResponse>>(
      `/livetrader-page-overview`,
      options
    );
  };

  public getTotalTradingValue = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<number>>(
      `/admin/trading-value`,
      options
    );
  };

  public pauseUnpauseTradingAccount = async (
    token: string,
    pause: boolean,
    tradingAccountId?: string
  ) => {
    const options = createOptions(token);
    const body = {
      paused: pause,
    };
    if (tradingAccountId) {
      body["trading_account_id"] = tradingAccountId;
    }
    return await http.post<CustomResponse<TradingAccounts>>(
      `/pause-trading-accounts`,
      body,
      options
    );
  };

  public cancelAllOrders = async (token: string, tradingAccountId: string) => {
    const options = createOptions(token);
    const body = {
      trading_account_id: tradingAccountId,
    };
    return await http.post<CustomResponse<any>>(
      `/trading-account/cancel-orders`,
      body,
      options
    );
  };

  public sellCurrenciesAtMarketValue = async (
    token: string,
    tradingAccountId: string,
    currenciesToSell: string[]
  ) => {
    const options = createOptions(token);
    const body = {
      trading_account_id: tradingAccountId,
      currencies_to_sell: currenciesToSell,
    };
    return await http.post<CustomResponse<SellHoldingResult[]>>(
      `/trading-account/sell-currencies`,
      body,
      options
    );
  };

  public loadLivetraderReport = async (
    token: string,
    tradingAccountId: string
  ) => {
    const options = createOptions(token);
    const body = {
      trading_account_id: tradingAccountId,
    };
    return await http.post<CustomResponse<LivetraderReport>>(
      `/livetrader-report/load`,
      body,
      options
    );
  };
  public getLivetraderLogs = async (
    token: string,
    tradingAccountId: string
  ) => {
    const options = createOptions(token);
    const body = {
      livewallet_id: tradingAccountId,
    };
    return await http.post<CustomResponse<LivetraderUserLogItem[]>>(
      `/livetrader-report/logs`,
      body,
      options
    );
  };
  public deleteLivetraderLog = async (
    token: string,
    tradingAccountId: string,
    logId: string
  ) => {
    const options = createOptions(token);
    const body = {
      livewallet_id: tradingAccountId,
      log_id: logId,
    };
    return await http.post<CustomResponse<any>>(
      `/livetrader-report/delete-log`,
      body,
      options
    );
  };

  // LIVETRADER ADMIN

  public getAdminLivetraderLogs = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<LivetraderUserLogItem[]>>(
      `/admin/livetrader-logs`,
      options
    );
  };

  public loadLivetraderReportAsAdmin = async (
    token: string,
    tradingAccountId: string
  ) => {
    const options = createOptions(token);
    const body = {
      trading_account_id: tradingAccountId,
    };
    return await http.post<CustomResponse<LivetraderReport>>(
      "/admin/livetrader-report/load",
      body,
      options
    );
  };
  public getUsers = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<{ [key: string]: AdminUserItem }>>(
      "/admin/users",
      options
    );
  };
  public getTradingAccountsAsAdmin = async (token: string) => {
    const options = createOptions(token);
    return await http.get<
      CustomResponse<{ [key: string]: AdminTradingAccountItem[] }>
    >("/admin/trading-accounts", options);
  };
  public getLivetraderOrderStats = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<LivetraderOrderStats>>(
      "/order-stats",
      options
    );
  };
}

export const LivetraderService = new LivetraderServiceClass(http);

export interface LivetraderOrderStats {
  total_trades: number;
  winning_trades: number;
  losing_trades: number;
  best_currencies: { [currency_name: string]: number } | null;
  best_strategy: BestStrategyStatItem | null;
}

export interface BestStrategyStatItem {
  strategy_id: string;
  version_id: string;
  strategy_name: string;
  wins: number;
}

export interface AdminTradingAccountItem {
  trading_account_id: string;
  active: boolean;
  exchange_id?: string;
}
export interface AdminUserItem {
  username: string;
  user_hash?: string;
  title?: string;
}

export interface TradingAccountUpdateQuery {
  exchange_id: string;
  active_slot: string;
  base_currency: string;
  trading_strategy: TradingStrategySlotInput;
  platform_credentials?: PlatformCredentialsInput | null;
  wallet_config?: WalletConfigurationInput;
}

export interface LivetraderPageOverviewResponse {
  orders: Order[];
}
