import "./subscriptions-modal.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useMemo, useState } from "react";
import {
  UserSubscriptionTier,
  UsermanagerService,
} from "../../../services/UserManagerService";
import { useQuery } from "react-query";
import { AnimatePresence, motion } from "framer-motion";
import { Image, SegmentedControl } from "@mantine/core";
import { TierBadges } from "../../../components/strategy-body/case-control/TierBadges";
import CountUp from "react-countup";
import { toTimestring } from "../../../utils/FormattingUtils";
import { CommonButton } from "../../../components/buttons/neoton-common-button/CommonButton";
import { FiArrowDown, FiArrowLeft } from "react-icons/fi";
import { MdChecklist, MdOutlineShoppingCartCheckout } from "react-icons/md";

import { ToastContext } from "../../../App";
import { getTheme } from "../../../utils/themeUtil";
import { IoMdWallet } from "react-icons/io";
import { IoCodeSlashSharp } from "react-icons/io5";
import { LuHistory } from "react-icons/lu";
import { AiOutlineLineChart } from "react-icons/ai";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { NeotonLoader } from "../../../components/custom-loaders/NeotonLoader";

interface Props {
  activeTheme: string;
  activeSubscription: UserSubscriptionTier | undefined;
  refetchSubscription: () => void;
}

export function SubscriptionsModal(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently } = useAuth0();
  const triggerToast = useContext(ToastContext);
  const theme = useMemo(() => {
    return getTheme(props.activeTheme);
  }, [props.activeTheme]);
  const activePlan = props.activeSubscription?.plan;
  const [selectedPlan, setSelectedPlan] = useState<string | undefined>(
    props.activeSubscription?.plan ?? "Free plan"
  );

  const [subscriptionInterval, setSubscriptionInterval] = useState<string>(
    props.activeSubscription?.interval ?? "month"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubscriptions, setLoadingSubscriptions] =
    useState<boolean>(false);
  const [viewInvoices, setViewInvoices] = useState<boolean>(false);

  const fetchSubscriptions = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setLoadingSubscriptions(true);
      const response = await UsermanagerService.GetSubscriptions(token);
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSubscriptions(false);
    }
  }, [getAccessTokenSilently, setLoadingSubscriptions]);

  const fetchInvoices = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const response = await UsermanagerService.GetStripeInvoices(token);
      return response.data;
    } catch (error) {}
  }, [getAccessTokenSilently]);

  const invoicesQuery = useQuery("invoices", fetchInvoices, {
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const invoices = useMemo(() => {
    return invoicesQuery.data?.payload?.data;
  }, [invoicesQuery.data?.payload]);

  const subscriptionsQuery = useQuery("subscriptions", fetchSubscriptions, {
    cacheTime: 0,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const freePlan: SubscriptionPlan | undefined = useMemo(() => {
    const plan = subscriptionsQuery.data?.payload?.products.find(
      (x) => x.name === "Free plan"
    );
    if (!plan) return undefined;
    const prices = subscriptionsQuery.data?.payload?.prices.filter(
      (x) => x.product === plan.id
    );
    const price = prices?.find(
      (x) => x.recurring?.interval === subscriptionInterval
    );
    if (!price) return undefined;
    return {
      name: plan.name,
      product_id: plan.id,
      image: plan.images[0],
      marketing_features: plan.marketing_features.map((x) => x.name),
      price: price.unit_amount,
      currency: price.currency,
      price_id: price.id,
      payment_interval: price.recurring?.interval,
      metadata: plan.metadata,
    };
  }, [subscriptionsQuery.data?.payload, subscriptionInterval]);

  const basicPlan: SubscriptionPlan | undefined = useMemo(() => {
    const plan = subscriptionsQuery.data?.payload?.products.find(
      (x) => x.name === "Basic plan"
    );
    if (!plan) return undefined;
    const prices = subscriptionsQuery.data?.payload?.prices.filter(
      (x) => x.product === plan.id
    );
    const price = prices?.find(
      (x) => x.recurring?.interval === subscriptionInterval
    );
    if (!price) return undefined;
    return {
      name: plan.name,
      product_id: plan.id,
      image: plan.images[0],
      marketing_features: plan.marketing_features.map((x) => x.name),
      price: price.unit_amount,
      currency: price.currency,
      price_id: price.id,
      payment_interval: price.recurring?.interval,
      metadata: plan.metadata,
    };
  }, [subscriptionsQuery.data?.payload, subscriptionInterval]);

  const proPlan: SubscriptionPlan | undefined = useMemo(() => {
    const plan = subscriptionsQuery.data?.payload?.products.find(
      (x) => x.name === "Pro plan"
    );
    if (!plan) return undefined;
    const prices = subscriptionsQuery.data?.payload?.prices.filter(
      (x) => x.product === plan.id
    );
    const price = prices?.find(
      (x) => x.recurring?.interval === subscriptionInterval
    );
    if (!price) return undefined;
    return {
      name: plan.name,
      product_id: plan.id,
      image: plan.images[0],
      marketing_features: plan.marketing_features.map((x) => x.name),
      price: price.unit_amount,
      currency: price.currency,
      price_id: price.id,
      payment_interval: price.recurring?.interval,
      metadata: plan.metadata,
    };
  }, [subscriptionsQuery.data?.payload, subscriptionInterval]);

  const planAmount = useMemo(() => {
    if (!selectedPlan || !subscriptionInterval) return 0;
    if (selectedPlan === "Free plan") return 0;
    if (selectedPlan === "Basic plan") return basicPlan?.price ?? 0;
    if (selectedPlan === "Pro plan") return proPlan?.price ?? 0;
    return 0;
  }, [selectedPlan, subscriptionInterval, basicPlan, proPlan]);

  const prepareCheckout = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token || !selectedPlan) return;
    try {
      setLoading(true);
      const response = await UsermanagerService.GetCheckoutSession(
        token,
        selectedPlan,
        subscriptionInterval
      );
      const sessionUrl = response.data.payload.url;
      window.open(sessionUrl, "_blank")?.focus();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [setLoading, getAccessTokenSilently, selectedPlan, subscriptionInterval]);

  const handleManageSubscription = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token || !activePlan) return;
    try {
      setLoading(true);
      const response = await UsermanagerService.GetCheckoutSession(
        token,
        activePlan,
        subscriptionInterval
      );
      const sessionUrl = response.data.payload.url;
      window.open(sessionUrl, "_blank")?.focus();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [setLoading, getAccessTokenSilently, activePlan, subscriptionInterval]);

  const handleCancelSubscription = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token || !props.activeSubscription) return;
    try {
      setLoading(true);
      const response = await UsermanagerService.CancelSubscription(token);
      props.refetchSubscription();
      if (response.data.payload) {
        const cancellationTimestamp = response.data.payload;
        triggerToast(
          `Your subscription has been cancelled. It will expire on ${toTimestring(
            cancellationTimestamp,
            "1D"
          )}.`,
          "info",
          null
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    getAccessTokenSilently,
    props.activeSubscription,
    triggerToast,
    setLoading,
    props.refetchSubscription,
  ]);

  const featureIconMap: { [key: string]: React.ReactNode } = useMemo(() => {
    const iconSize = 26;

    return {
      free: (
        <div className="feature-icon-container">
          <MdChecklist color={theme.text} size={iconSize} />
        </div>
      ),
      basic: (
        <div className="feature-icon-container">
          <MdChecklist color={theme.basicPlanHover} size={iconSize} />
        </div>
      ),
      strategies: (
        <div className="feature-icon-container">
          <IoCodeSlashSharp size={iconSize} />
        </div>
      ),
      backtester: (
        <div className="feature-icon-container">
          <LuHistory size={iconSize} />
        </div>
      ),
      livetrader: (
        <div className="feature-icon-container">
          <IoMdWallet size={iconSize} />
        </div>
      ),
      papertrader: (
        <div className="feature-icon-container">
          <AiOutlineLineChart size={iconSize} />
        </div>
      ),
      ai: (
        <TierBadges
          noMargin={true}
          size="xs"
          collectedCriterias={[]}
          tiersList={["AI"]}
        />
      ),
      ta: (
        <TierBadges
          noMargin={true}
          size="xs"
          collectedCriterias={[]}
          tiersList={["TA"]}
        />
      ),
      pt: (
        <TierBadges
          noMargin={true}
          size="xs"
          collectedCriterias={[]}
          tiersList={["PT"]}
        />
      ),
    };
  }, [theme]);

  const renderPlan = useCallback(
    (plan: SubscriptionPlan) => {
      const planKey = plan.name.replace(" ", "-").toLowerCase();
      const active = selectedPlan === plan.name;
      return (
        <motion.div
          key={plan.name}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className={
            "subscription-plan-container " + planKey + (active ? " active" : "")
          }
          onClick={() => {
            if (plan.name === "Free plan") return;
            if (
              props.activeSubscription?.plan === "Pro plan" &&
              props.activeSubscription.expires === -1
            )
              return;
            setSelectedPlan(plan.name);
          }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2, delay: 1.2 }}
          >
            <Image src={plan.image} alt={plan.name} radius={"md"} />
          </motion.div>

          <div className="marketing-features-container">
            <label className="dimmed-label">Features:</label>
            {plan.marketing_features.map((feature, idx) => {
              const rowName = `row_${idx}`;
              const featureKey = plan?.metadata?.[rowName];
              const marketingFeatureIcon = featureIconMap[featureKey];

              return (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2, delay: idx * 0.1 + 1 }}
                  key={idx}
                  className="feature"
                >
                  {marketingFeatureIcon}
                  <label className="feature-label">{feature}</label>
                </motion.div>
              );
            })}
          </div>
          {plan.name === "Free plan" ? undefined : (
            <div className="plan-price-label-container">
              <CountUp
                className={"plan-price-label"}
                end={plan.price / 100}
                preserveValue
                duration={1}
                style={{
                  textDecoration:
                    plan.name === "Free plan" ? "line-through" : undefined,
                  opacity: plan.name === "Free plan" ? 0.5 : 1,
                }}
                prefix="€ "
                decimals={2}
              />
              <label className="dimmed-label">/ {plan.payment_interval}</label>
            </div>
          )}
        </motion.div>
      );
    },
    [selectedPlan, props.activeSubscription, featureIconMap]
  );

  return (
    <AnimatePresence exitBeforeEnter>
      <div className="subscriptions-container">
        {loadingSubscriptions ? (
          <NeotonLoader />
        ) : (
          <>
            <div className="subscription-interval-container">
              {!viewInvoices && (
                <>
                  <label className="dimmed-label">Payment interval</label>
                  <SegmentedControl
                    title="Subscription interval"
                    size="sm"
                    data={[
                      { label: "Monthly", value: "month" },
                      { label: "Yearly", value: "year" },
                    ]}
                    value={subscriptionInterval}
                    onChange={(value) =>
                      setSubscriptionInterval(value as string)
                    }
                  />
                </>
              )}
              {invoices && invoices.length > 0 && (
                <>
                  {viewInvoices ? (
                    <CommonButton
                      style={{ marginLeft: "auto", marginRight: "5px" }}
                      flat
                      compact
                      borderTheme="gray-accent"
                      activeTheme={props.activeTheme}
                      label="Back to plans"
                      leftIcon={<FiArrowLeft />}
                      onClick={() => setViewInvoices(false)}
                    />
                  ) : (
                    <CommonButton
                      style={{ marginLeft: "auto", marginRight: "5px" }}
                      flat
                      compact
                      borderTheme="gray-accent"
                      activeTheme={props.activeTheme}
                      label="Invoices"
                      leftIcon={<FaFileInvoiceDollar />}
                      onClick={() => setViewInvoices(true)}
                    />
                  )}
                </>
              )}
            </div>
            {viewInvoices ? (
              <div className="invoices-container">
                <label
                  style={{
                    alignSelf: "center",
                  }}
                  className="dimmed-label"
                >
                  {invoices?.length} invoice(s) found for your account
                </label>
                {invoices?.map((invoice, idx) => {
                  return (
                    <motion.div
                      key={idx}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: .8, y: 0 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      whileHover={{ opacity: 1 }}
                      className="invoice-container"
                      onClick={() =>
                        window
                          .open(invoice.hosted_invoice_url, "_blank")
                          ?.focus()
                      }
                    >
                      {invoice.paid ? (
                        <label className="invoice-label paid">Paid</label>
                      ) : (
                        <label className="invoice-label unpaid">Unpaid</label>
                      )}

                      <label className="dimmed-label">Effective at:</label>
                      <label>{toTimestring(invoice.effective_at * 1000)}</label>

                      <label
                        className="dimmed-label"
                        style={{
                          marginLeft: "auto",
                        }}
                      >
                        Click for details
                      </label>

                      <label
                        className="dimmed-label"
                        style={{
                          marginLeft: "auto",
                        }}
                      >
                        Amount:
                      </label>
                      <CountUp
                        className={"wallet-stat-label"}
                        end={invoice.amount_due / 100}
                        preserveValue
                        duration={1}
                        prefix="€ "
                        decimals={2}
                      />
                    </motion.div>
                  );
                })}
              </div>
            ) : (
              <>
                <div className="plans-container">
                  {freePlan && renderPlan(freePlan)}
                  {basicPlan && renderPlan(basicPlan)}
                  {proPlan && renderPlan(proPlan)}
                </div>
                {activePlan && (
                  <div
                    className={
                      "active-plan-container " +
                      activePlan.replace(" ", "-").toLowerCase()
                    }
                  >
                    <div className="active-plan-row">
                      <label className="dimmed-label">Active plan:</label>
                      <motion.label
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, fontSize: "smaller" }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="subscription-plan-label"
                      >
                        {activePlan}
                      </motion.label>
                    </div>
                    <div className="active-plan-row">
                      <label className="dimmed-label">
                        {props.activeSubscription?.cancelled === false
                          ? "Next payment"
                          : "Expires"}
                        :
                      </label>
                      <motion.label
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, fontSize: "smaller" }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        {props.activeSubscription?.expires === -1 ||
                        props.activeSubscription?.plan === "Free plan"
                          ? "Never"
                          : props.activeSubscription?.expires === 0
                          ? "TBD"
                          : toTimestring(
                              props.activeSubscription?.expires,
                              "1D"
                            )}
                      </motion.label>
                    </div>
                    {props.activeSubscription?.plan !== "Free plan" && (
                      <CommonButton
                        compact
                        onClick={handleManageSubscription}
                        loading={loading}
                        activeTheme={props.activeTheme}
                        label="Manage subscription"
                        borderTheme="gray-accent"
                      />
                    )}
                    {props.activeSubscription?.cancelled === false &&
                      props.activeSubscription?.expires > 0 &&
                      props.activeSubscription.plan !== "Free plan" && (
                        <CommonButton
                          compact
                          onClick={handleCancelSubscription}
                          loading={loading}
                          activeTheme={props.activeTheme}
                          label="Cancel subscription"
                          borderTheme="red-accent"
                        />
                      )}
                  </div>
                )}

                {selectedPlan && selectedPlan !== activePlan && (
                  <>
                    <CommonButton
                      compact
                      style={{
                        alignSelf: "center",
                      }}
                      flat
                      activeTheme={props.activeTheme}
                      label="Selected plan"
                      leftIcon={<FiArrowDown />}
                      onClick={() => setSelectedPlan(undefined)}
                    />
                    <motion.div
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.2, delay: 0.2 }}
                      className={
                        "selected-plan-container " +
                        selectedPlan.replace(" ", "-").toLowerCase()
                      }
                    >
                      <div className="active-plan-row">
                        <label className="dimmed-label">Selected plan:</label>
                        <motion.label
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1, fontSize: "smaller" }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className="subscription-plan-label"
                        >
                          {selectedPlan}
                        </motion.label>
                      </div>
                      <div className="active-plan-row">
                        {planAmount ? (
                          <>
                            <label className="dimmed-label">Price:</label>
                            <CountUp
                              className={"wallet-stat-label"}
                              end={planAmount / 100}
                              start={0}
                              preserveValue
                              duration={1}
                              prefix="€ "
                              suffix={` / ${
                                selectedPlan === "Basic plan"
                                  ? basicPlan?.payment_interval
                                  : proPlan?.payment_interval
                              }`}
                              decimals={2}
                            />
                          </>
                        ) : undefined}
                      </div>
                      <CommonButton
                        leftIcon={<MdOutlineShoppingCartCheckout />}
                        activeTheme={props.activeTheme}
                        loading={loading}
                        onClick={prepareCheckout}
                        label="Proceed"
                        borderTheme="gray-accent"
                      />
                    </motion.div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </AnimatePresence>
  );
}

export interface SubscriptionPlan {
  name: string;
  product_id: string;
  image: string;
  marketing_features: string[];
  price: number;
  currency: string;
  price_id: string;
  payment_interval: string | undefined;
  metadata?: any;
}

export interface StripeInvoice {
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  currency: string;
  paid: boolean;
  effective_at: number;
  hosted_invoice_url: string;
}
