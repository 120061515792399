import "./common-chart-navigation.scss";
import { Currency } from "../../../interfaces/Currency";
import { CurrencyPriceData } from "../../../interfaces/PricesOverview";
import { NavigationChart } from "../../chart-components/navigation-chart/NavigationChart";
import { getCurrencyUrl } from "../../../utils/cdnUtil";
import { Avatar } from "@mantine/core";
import { motion } from "framer-motion";
import { FiX } from "react-icons/fi";
import { BiCandles } from "react-icons/bi";

interface Props {
  activeTheme: string;
  selectedCurrency: Currency | undefined;
  currencySelection: Currency[];
  onCurrencySelection: (currency: Currency) => void;
  opened: boolean;
  setOpened: (opened: boolean) => void;
  candleSize: string;
  priceData: CurrencyPriceData | undefined;
  currentInterval: [number, number];
  handleNavigationChartClick: (
    _candleSize: string,
    centerTimestamp: number | null
  ) => void;
  onCurrencyClear?: () => void;
  sessionInterval?: [number, number];
  enable1DChartClick?: boolean;
  descriptionChild?: React.ReactNode;
}
export function CommonChartNavigation(props: React.PropsWithChildren<Props>) {
  return (
    <div className="navigation-chart-outer-container">
      <motion.div
        className={
          "currency-selection-button" + (props.opened ? " opened" : "")
        }
        initial={{ opacity: 0, scale: 1.3 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.25, delay: 0.25 }}
        onClick={() => props.setOpened(!props.opened)}
      >
        <motion.div
          initial={{
            opacity: 0,
            scale: 1.3,
          }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          transition={{
            duration: 0.25,
            delay: 0.25,
          }}
        >
          {props.selectedCurrency ? (
            <Avatar
              src={getCurrencyUrl(props.selectedCurrency)}
              size={"sm"}
              radius={"xl"}
            />
          ) : (
            <BiCandles size={24}/>
          )}
        </motion.div>
      </motion.div>

      {props.opened && (
        <div className="currencies-select-popup">
          {props.currencySelection &&
            props.currencySelection.map((currency, idx) => {
              return (
                <motion.div
                  key={currency.currency_name}
                  initial={{ opacity: 0, scale: 0.2 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.25, delay: idx * 0.009 }}
                  className="new-currency-selection-item"
                  onClick={() => props.onCurrencySelection(currency)}
                  id={
                    props.selectedCurrency?.currency_name ===
                    currency.currency_name
                      ? "selected-currency-selection-item"
                      : ""
                  }
                >
                  <Avatar
                    src={getCurrencyUrl(currency)}
                    size={26}
                    radius={"lg"}
                  />
                  <label>{currency.currency_name}</label>
                </motion.div>
              );
            })}
        </div>
      )}
      {props.priceData && (
        <div className="navigation-chart-frame">
          <NavigationChart
            candleSize={props.candleSize}
            activeTheme={props.activeTheme}
            priceData={props.priceData}
            handleNavigationChartClick={props.handleNavigationChartClick}
            currentInterval={props.currentInterval}
            sessionInterval={props.sessionInterval}
            enable1DChartClick={props.enable1DChartClick}
          />
        </div>
      )}
      {props.onCurrencyClear && props.selectedCurrency && (
        <motion.div
          className={"currency-deselect-button"}
          initial={{ opacity: 0, scale: 1.3 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.25, delay: 0.25 }}
          onClick={() => props.onCurrencyClear && props.onCurrencyClear()}
        >
          <FiX />
        </motion.div>
      )}
      {props.descriptionChild && (
        <motion.div
          className="description-child-container"
          initial={{ opacity: 0, scale: 1.3 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.25, delay: 0.25 }}
        >
          {props.descriptionChild}
        </motion.div>
      )}
    </div>
  );
}
