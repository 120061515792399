import "./common-user-stats.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { UserInfo } from "../../../interfaces/user/UserInfo";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { LivetraderService } from "../../../services/LivetraderService";
import { useQuery } from "react-query";
import { MetricsPieChart } from "../../chart-components/pie-chart/MetricsPieChart";
import { motion, useInView } from "framer-motion";
import CountUp from "react-countup";
import { Avatar, Tooltip } from "@mantine/core";
import { NeotonLoader } from "../../custom-loaders/NeotonLoader";
import { getTheme } from "../../../utils/themeUtil";
import { IoIosArrowDown } from "react-icons/io";
import { CgArrowsExchange } from "react-icons/cg";
import { VscHistory } from "react-icons/vsc";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import { getCurrencyUrl } from "../../../utils/cdnUtil";
import { StrategyVersionIcon } from "../identicon/StrategyVersionIcon";
import {
  convertSecondsToFormattedTime,
  toTimestring,
} from "../../../utils/FormattingUtils";
import { UserAchievements } from "../achievements/UserAchievements";

interface Props {
  activeTheme: string;
  userInfo: UserInfo | undefined;
  height?: number;
  compact?: boolean;
  compactExpanded?: boolean;
}
export function CommonUserStats(props: React.PropsWithChildren<Props>) {
  const theme = useMemo(() => getTheme(props.activeTheme), [props.activeTheme]);
  const { getAccessTokenSilently } = useAuth0();
  const currencies = useContext(CurrencyContext);

  const [loadingOrderStats, setLoadingOrderStats] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const achievementsRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(achievementsRef);

  const fetchLivetraderOrderStats = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setLoadingOrderStats(true);
      const response = await LivetraderService.getLivetraderOrderStats(token);
      return response.data.payload;
    } finally {
      setLoadingOrderStats(false);
    }
  }, [getAccessTokenSilently, setLoadingOrderStats]);

  const livetraderOrderStatsQuery = useQuery(
    "livetraderOrderStats",
    fetchLivetraderOrderStats,
    {
      cacheTime: 1000 * 60 * 60,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  const showOnCompactComponent = useMemo(() => {
    if (!props.compact) return true;
    if (props.compact && props.compactExpanded === false) return false;
    return true;
  }, [props.compact, props.compactExpanded]);

  const winOpacity = useMemo(() => {
    if (!livetraderOrderStatsQuery.data) return 0;
    if (livetraderOrderStatsQuery.data.total_trades === 0) return 0;
    return (
      livetraderOrderStatsQuery.data.winning_trades /
      livetraderOrderStatsQuery.data.total_trades
    );
  }, [livetraderOrderStatsQuery.data]);

  const lossOpacity = useMemo(() => {
    if (!livetraderOrderStatsQuery.data) return 0;
    if (livetraderOrderStatsQuery.data.total_trades === 0) return 0;
    return (
      livetraderOrderStatsQuery.data.losing_trades /
      livetraderOrderStatsQuery.data.total_trades
    );
  }, [livetraderOrderStatsQuery.data]);

  const renderBestCurrencies = useCallback(() => {
    if (!livetraderOrderStatsQuery.data || !currencies) return;
    if (!livetraderOrderStatsQuery.data.best_currencies) return;
    const top3Currencies = Object.keys(
      livetraderOrderStatsQuery.data.best_currencies
    )
      .sort(
        (a, b) =>
          (livetraderOrderStatsQuery.data?.best_currencies?.[b] ?? 0) -
          (livetraderOrderStatsQuery.data?.best_currencies?.[a] ?? 0)
      )
      .slice(0, 3);

    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="common-user-stat-row-chip"
      >
        {top3Currencies.map((currency_name) => {
          const currency = currencies.find(
            (c) => c.currency_name === currency_name
          );
          const winCount =
            livetraderOrderStatsQuery.data?.best_currencies?.[currency_name] ??
            0;
          if (!currency || winCount === 0) return;
          return (
            <Tooltip
              key={currency.currency_name}
              label={
                <>
                  <label>
                    <strong>{currency_name}</strong>
                  </label>
                  <br />
                  <label>Wins: {winCount}</label>
                </>
              }
              withArrow
            >
              <Avatar
                src={getCurrencyUrl(currency)}
                alt={currency.currency_name}
                size={24}
                radius="xl"
              />
            </Tooltip>
          );
        })}
      </motion.div>
    );
  }, [livetraderOrderStatsQuery.data, currencies]);

  const renderBestStrategy = useCallback(() => {
    if (
      !livetraderOrderStatsQuery.data ||
      !livetraderOrderStatsQuery.data.best_strategy
    )
      return;
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="common-user-stat-row-chip"
      >
        <StrategyVersionIcon
          activeTheme={props.activeTheme}
          included_currencies={[]}
          strategy_id={livetraderOrderStatsQuery.data.best_strategy.strategy_id}
          version_id={livetraderOrderStatsQuery.data.best_strategy.version_id}
          name={livetraderOrderStatsQuery.data.best_strategy.strategy_name}
          size={20}
        />

        <CountUp
          className={"common-point-grant-icon"}
          end={livetraderOrderStatsQuery.data.best_strategy.wins}
          preserveValue
          duration={1.1}
          suffix={
            livetraderOrderStatsQuery.data.best_strategy.wins > 1
              ? " wins"
              : " win"
          }
        />
      </motion.div>
    );
  }, [livetraderOrderStatsQuery.data, props.activeTheme]);

  const dailyBacktestDuration = useMemo(() => {
    if (!props.userInfo?.daily_backtest_billing_seconds) return 0;
    return props.userInfo.daily_backtest_billing_seconds;
  }, [props.userInfo?.daily_backtest_billing_seconds]);

  const totalBacktestDuration = useMemo(() => {
    if (!props.userInfo?.total_backtest_billing_seconds) return 0;
    return props.userInfo.total_backtest_billing_seconds;
  }, [props.userInfo?.total_backtest_billing_seconds]);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const displayEarnedPoints = useMemo(() => {
    if (!props.userInfo?.neoton_points_earned) return false;
    return (
      props.userInfo.neoton_points_balance !==
      props.userInfo.neoton_points_earned
    );
  }, [props.userInfo]);

  const renderBacktestBillingDuration = useCallback(
    (seconds: number, isDaily: boolean = false) => {
      const formattedTime = convertSecondsToFormattedTime(seconds);
      return (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className={"common-user-stat-row-chip" + (isDaily ? " daily" : "")}
        >
          <Tooltip
            label={
              isDaily
                ? "Time spent backtesting today"
                : "Time spent backtesting in total"
            }
            withArrow
          >
            <VscHistory />
          </Tooltip>
          {formattedTime.days > 0 && (
            <CountUp
              className={"common-point-grant-icon"}
              end={formattedTime.days}
              preserveValue
              suffix="d"
            />
          )}
          {formattedTime.hours > 0 && (
            <CountUp
              className={"common-point-grant-icon"}
              end={formattedTime.hours}
              preserveValue
              suffix="h"
            />
          )}
          {formattedTime.minutes > 0 && (
            <CountUp
              className={"common-point-grant-icon"}
              end={formattedTime.minutes}
              preserveValue
              suffix="m"
            />
          )}
          {formattedTime.seconds > 0 && (
            <CountUp
              className={"common-point-grant-icon"}
              end={formattedTime.seconds}
              preserveValue
              suffix="s"
            />
          )}
        </motion.div>
      );
    },
    [convertSecondsToFormattedTime]
  );

  return (
    <div
      className="common-user-stats-outer-container"
      style={{ maxHeight: 200 }}
      ref={containerRef}
    >
      <div className="common-user-stats-container" style={{ minHeight: 230 }}>
        <div
          className="common-user-stat-column"
          style={{ alignItems: "flex-start" }}
        >
          {props.compact && props.userInfo?.subscription_tier && (
            <>
              <div className="common-user-stat-row">
                <label>{props.userInfo?.subscription_tier.plan} active</label>
              </div>
              {showOnCompactComponent &&
                props.userInfo?.subscription_tier?.expires && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    className="common-user-stat-row"
                  >
                    <label className="dimmed-label">
                      Next billing:{" "}
                      {props.userInfo?.subscription_tier.expires <= 0
                        ? "Never"
                        : toTimestring(
                            props.userInfo?.subscription_tier.expires
                          )}
                    </label>
                  </motion.div>
                )}
            </>
          )}
          <div className="common-user-stat-row">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className="common-user-stat-row-chip balance"
            >
              <Tooltip label="Available credits" withArrow>
                <label>E</label>
              </Tooltip>

              <CountUp
                className={"common-point-grant-icon"}
                end={props.userInfo?.neoton_points_balance ?? 0}
                preserveValue
                duration={1.1}
              />
            </motion.div>
            {displayEarnedPoints && (
              <>
                /
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                  className="common-user-stat-row-chip"
                >
                  <Tooltip label="Total earned credits" withArrow>
                    <label>E</label>
                  </Tooltip>
                  <CountUp
                    className={"common-point-grant-icon"}
                    end={props.userInfo?.neoton_points_earned ?? 0}
                    preserveValue
                    duration={1.1}
                  />
                </motion.div>
              </>
            )}
          </div>
          {showOnCompactComponent && (
            <div className="common-user-stat-row">
              {dailyBacktestDuration > 0 && (
                <>
                  {renderBacktestBillingDuration(dailyBacktestDuration, true)} /
                </>
              )}
              {totalBacktestDuration > 0 &&
                renderBacktestBillingDuration(totalBacktestDuration)}
            </div>
          )}
          {showOnCompactComponent && (
            <div className="common-user-stat-row">
              {(livetraderOrderStatsQuery.data?.total_trades ?? 0) > 1 && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                  className="common-user-stat-row-chip trades"
                >
                  <Tooltip label="Total trades" withArrow>
                    <CgArrowsExchange />
                  </Tooltip>
                  <CountUp
                    className={"common-point-grant-icon"}
                    end={livetraderOrderStatsQuery.data?.total_trades ?? 0}
                    preserveValue
                    duration={1.1}
                  />
                </motion.div>
              )}
              {renderBestCurrencies()}
              {renderBestStrategy()}
            </div>
          )}
        </div>
        {showOnCompactComponent && (
          <div
            className="common-user-stat-column"
            style={{
              minWidth: 200,
              minHeight: 200,
            }}
          >
            {loadingOrderStats ? (
              <NeotonLoader />
            ) : (
              <>
                {livetraderOrderStatsQuery &&
                  (livetraderOrderStatsQuery.data?.total_trades ?? 0) > 2 && (
                    <>
                      <motion.div
                        initial={{ opacity: 0, y: -40, scale: 0 }}
                        animate={{
                          opacity: winOpacity,
                          scale: 1,
                          backgroundColor: theme.buyOrderStrokeHover,
                        }}
                        transition={{ duration: 0.5 }}
                        className="wl-accent"
                      />
                      <div>
                        <MetricsPieChart
                          activeTheme={props.activeTheme}
                          losses={
                            livetraderOrderStatsQuery.data?.losing_trades ?? 0
                          }
                          trades={
                            livetraderOrderStatsQuery.data?.total_trades ?? 0
                          }
                          wins={
                            livetraderOrderStatsQuery.data?.winning_trades ?? 0
                          }
                          hideTotal
                          disableTooltip
                        />
                      </div>
                      <motion.div
                        initial={{ opacity: 0, y: 40, scale: 0 }}
                        animate={{
                          opacity: lossOpacity,
                          scale: 1,
                          backgroundColor: theme.sellOrderStrokeHover,
                        }}
                        transition={{ duration: 0.5 }}
                        className="wl-accent"
                      />
                    </>
                  )}
              </>
            )}
          </div>
        )}
      </div>
      {!props.compact && (
        <motion.div
          className="achievements-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          style={{
            minHeight: 200,
          }}
        >
          <label
            onClick={isInView ? scrollToTop : scrollToBottom}
            className="achievements-header-label"
          >
            Achievements
            <motion.div
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
              animate={{ rotate: isInView ? 180 : 0 }}
              transition={{ duration: 0.5 }}
            >
              <IoIosArrowDown size={20} />
            </motion.div>
          </label>
          <motion.div
            ref={achievementsRef}
            className="achievements-content"
            initial={{ opacity: 0 }}
            animate={{
              opacity: isInView ? 1 : 0,
            }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <UserAchievements
              activeTheme={props.activeTheme}
              user_hash={props.userInfo?.user_hash ?? undefined}
            />
          </motion.div>
        </motion.div>
      )}
    </div>
  );
}

export interface BacktestBillingDuration {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
