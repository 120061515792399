//import "./strategy-header.scss";
import { Avatar, MantineSize, RangeSlider, Tooltip } from "@mantine/core";
import { Currency } from "../../interfaces/Currency";
import {
  FixedPoolComposition,
  StrategyVersion,
} from "../../interfaces/strategyInterfaces/Strategy";
import { motion } from "framer-motion";
import { useCallback, useContext, useMemo } from "react";
import { UpdateStrategyHeaderContext } from "../../pages/strategy-page/StrategyPage";
import { getTheme } from "../../utils/themeUtil";
import { useForceUpdate, useMediaQuery } from "@mantine/hooks";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { getCurrencyUrl } from "../../utils/cdnUtil";
import { CurrencyLogoArray } from "../common/common-currencies/CurrencyLogoArray";
import { CommonButton } from "../buttons/neoton-common-button/CommonButton";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdOutlineDynamicFeed, MdDynamicForm } from "react-icons/md";
import {
  SelectedCurrencyContext,
  SetSelectedCurrencyContext,
} from "../shared-context/HandleCurrencyContext";

interface Props {
  activeTheme: string;
  strategy: StrategyVersion;
  tradingCurrencies: Currency[];
}
export function PublicHeaderEditor(props: React.PropsWithChildren<Props>) {
  const theme = getTheme(props.activeTheme);
  const updateHeader = useContext(UpdateStrategyHeaderContext);
  const smallScreen = useMediaQuery("(max-width: 1562px)");
  const tooltipIconSize = 18;
  const forceUpdate = useForceUpdate();

  const selectedCurrency = useContext(SelectedCurrencyContext);
  const setSelectedCurrency = useContext(SetSelectedCurrencyContext);

  const poolCompositionMode = useMemo(() => {
    return props.strategy.header.pool_composition_type || "dynamic";
  }, [props.strategy.header?.pool_composition_type]);

  const fundsDistribtutionTooltipDescription = (
    <label>
      Determines the allocation of funds in the wallet across High, Medium, and
      Low market cap pools.
    </label>
  );

 /*  const mcPoolTooltipDescription = (
    <label>
      3 pools - High, Medium, and Low - based on the market cap of
      cryptocurrencies.
      <br /> The pool assignment of each cryptocurrency is dynamic, shifting
      with market cap changes
    </label>
  ); */

  const rankDistributionTooltipDescription = (
    <>
      <label>
        Defines the allocation of cryptocurrencies within market cap pools,
        <br /> based on their rank as per Coinmarketcap's ranking system.
        <br /> Two adjustable sliders set the rank boundaries for each pool,
        allowing custom asset allocation.
      </label>
      <br />
      <label>
        <strong>MC rank</strong> or <strong>Market Capitalization rank</strong>,
        is a metric used to determine the size of a cryptocurrency in comparison
        to others by its market capitalization. Market capitalization is
        calculated by multiplying the current price of a currency by its
        circulating supply. A lower MC Rank indicates a larger market
        capitalization, suggesting that the currency is more valuable and has a
        bigger share of the market. This ranking helps investors understand a
        currency's relative size and significance in the market, guiding
        investment decisions.
      </label>
    </>
  );

  const sampleAmount = 1000; // 1000 dollars

  const dynamicCurrencyPoolHigh = useMemo(
    () =>
      props.tradingCurrencies.filter(
        (currency) =>
          currency.cmc_rank <= props.strategy.header.rank_distribution.high
      ),
    [props.tradingCurrencies, props.strategy.header.rank_distribution.high]
  );

  const dynamicCurrencyPoolMedium = useMemo(
    () =>
      props.tradingCurrencies.filter(
        (currency) =>
          currency.cmc_rank > props.strategy.header.rank_distribution.high &&
          currency.cmc_rank <= props.strategy.header.rank_distribution.medium
      ),
    [
      props.tradingCurrencies,
      props.strategy.header.rank_distribution.high,
      props.strategy.header.rank_distribution.medium,
    ]
  );

  const dynamicCurrencyPoolLow = useMemo(
    () =>
      props.tradingCurrencies.filter(
        (currency) =>
          currency.cmc_rank > props.strategy.header.rank_distribution.medium
      ),
    [props.tradingCurrencies, props.strategy.header.rank_distribution.medium]
  );

  const fixedCurrencyPoolHigh =
    !props.strategy.header.fixed_pool_composition || !props.strategy
      ? []
      : props.tradingCurrencies.filter((currency) =>
          props.strategy.header.fixed_pool_composition?.high.includes(
            currency.currency_name
          )
        );

  const fixedCurrencyPoolMedium =
    !props.strategy.header.fixed_pool_composition || !props.strategy
      ? []
      : props.tradingCurrencies.filter((currency) =>
          props.strategy.header.fixed_pool_composition?.medium.includes(
            currency.currency_name
          )
        );

  const fixedCurrencyPoolLow =
    !props.strategy.header.fixed_pool_composition || !props.strategy
      ? []
      : props.tradingCurrencies.filter((currency) =>
          props.strategy.header.fixed_pool_composition?.low.includes(
            currency.currency_name
          )
        );

  const moveCurrencyToPool = useCallback(
    (currency: Currency, from_pool: string, to_pool: string) => {
      const _fixedPoolComposition =
        props.strategy.header.fixed_pool_composition;
      if (!_fixedPoolComposition) return;
      const _from_pool = _fixedPoolComposition[from_pool];
      const _to_pool = _fixedPoolComposition[to_pool];
      if (!_from_pool || !_to_pool) return;
      const _currencyIndex = _from_pool.indexOf(currency.currency_name);
      if (_currencyIndex === -1) return;
      _from_pool.splice(_currencyIndex, 1);
      _to_pool.push(currency.currency_name);
      _fixedPoolComposition[from_pool] = _from_pool;
      _fixedPoolComposition[to_pool] = _to_pool;

      updateHeader({
        ...props.strategy.header,
        fixed_pool_composition: _fixedPoolComposition,
      });
      forceUpdate();
    },
    [props.strategy.header, updateHeader, forceUpdate]
  );

  const highActivePoolLength =
    poolCompositionMode === "fixed"
      ? fixedCurrencyPoolHigh.length
      : dynamicCurrencyPoolHigh.length;
  const mediumActivePoolLength =
    poolCompositionMode === "fixed"
      ? fixedCurrencyPoolMedium.length
      : dynamicCurrencyPoolMedium.length;
  const lowActivePoolLength =
    poolCompositionMode === "fixed"
      ? fixedCurrencyPoolLow.length
      : dynamicCurrencyPoolLow.length;

  const noCurrenciesInPoolText = "\nNo currencies in this pool";
  const noFundsInPoolText = "\nNo funds in this pool";

  const highMcPoolRemarksText = useMemo(() => {
    if (
      highActivePoolLength === 0 &&
      props.strategy.header.mc_distribution.high === 0
    )
      return undefined;
    if (
      highActivePoolLength > 0 &&
      props.strategy.header.mc_distribution.high > 0
    )
      return undefined;
    if (
      highActivePoolLength === 0 &&
      props.strategy.header.mc_distribution.high > 0
    )
      return noCurrenciesInPoolText;
    if (
      highActivePoolLength > 0 &&
      props.strategy.header.mc_distribution.high === 0
    )
      return noFundsInPoolText;
  }, [highActivePoolLength, props, noCurrenciesInPoolText, noFundsInPoolText]);

  const mediumMcPoolRemarksText = useMemo(() => {
    if (
      mediumActivePoolLength === 0 &&
      props.strategy.header.mc_distribution.medium === 0
    )
      return undefined;
    if (
      mediumActivePoolLength > 0 &&
      props.strategy.header.mc_distribution.medium > 0
    )
      return undefined;
    if (
      mediumActivePoolLength === 0 &&
      props.strategy.header.mc_distribution.medium > 0
    )
      return noCurrenciesInPoolText;
    if (
      mediumActivePoolLength > 0 &&
      props.strategy.header.mc_distribution.medium === 0
    )
      return noFundsInPoolText;
  }, [
    mediumActivePoolLength,
    props,
    noCurrenciesInPoolText,
    noFundsInPoolText,
  ]);

  const lowMcPoolRemarksText = useMemo(() => {
    if (
      lowActivePoolLength === 0 &&
      props.strategy.header.mc_distribution.low === 0
    )
      return undefined;
    if (
      lowActivePoolLength > 0 &&
      props.strategy.header.mc_distribution.low > 0
    )
      return undefined;
    if (
      lowActivePoolLength === 0 &&
      props.strategy.header.mc_distribution.low > 0
    )
      return noCurrenciesInPoolText;
    if (
      lowActivePoolLength > 0 &&
      props.strategy.header.mc_distribution.low === 0
    )
      return noFundsInPoolText;
  }, [lowActivePoolLength, props, noCurrenciesInPoolText, noFundsInPoolText]);

  const highPoolIconSize = smallScreen
    ? "xs"
    : highActivePoolLength > 6
    ? "xs"
    : "sm";
  const mediumPoolIconSize = smallScreen
    ? "xs"
    : mediumActivePoolLength > 6
    ? "xs"
    : "sm";
  const lowPoolIconSize = smallScreen
    ? "xs"
    : lowActivePoolLength > 6
    ? "xs"
    : "sm";

  const highPoolLabel =
    poolCompositionMode === "fixed"
      ? "High fixed pool"
      : "High Market Cap pool";
  const mediumPoolLabel =
    poolCompositionMode === "fixed"
      ? "Medium fixed pool"
      : "Medium Market Cap pool";
  const lowPoolLabel =
    poolCompositionMode === "fixed" ? "Low fixed pool" : "Low Market Cap pool";

  const highPoolSubLabel =
    poolCompositionMode === "fixed"
      ? "High"
      : `#1 - #${props.strategy.header.rank_distribution.high}`;
  const mediumPoolSubLabel =
    poolCompositionMode === "fixed"
      ? "Medium"
      : `#${props.strategy.header.rank_distribution.high + 1} - #${
          props.strategy.header.rank_distribution.medium
        }`;
  const lowPoolSubLabel =
    poolCompositionMode === "fixed"
      ? "Low"
      : `#${props.strategy.header.rank_distribution.medium + 1}+`;

  const renderDynamicPoolComposition = useCallback(
    (
      _currencies: Currency[],
      _size: MantineSize,
      previewAmount: number = 5
    ) => {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.5 }}
          className="currency-array-container"
        >
          {_currencies.length === 0 ? (
            <label className="dimmed-label">Empty</label>
          ) : (
            <CurrencyLogoArray
              currencies={_currencies}
              previewAmount={previewAmount}
              size={_size}
              expandable
            />
          )}
        </motion.div>
      );
    },
    []
  );

  const renderFixedPoolComposition = useCallback(
    (
      _currencies: Currency[],
      _size: MantineSize,
      pool: "high" | "medium" | "low"
    ) => (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.5 }}
        className="currency-array-container"
      >
        {_currencies.length === 0 ? (
          <label className="dimmed-label">Empty</label>
        ) : (
          <>
            {_currencies.map((currency, idx) => (
              <div className="fixed-currency-array-item" key={idx}>
                <Tooltip
                  key={idx}
                  className={
                    "currency-array-item" +
                    (currency.currency_name === selectedCurrency?.currency_name
                      ? " selected"
                      : "")
                  }
                  label={`#${currency.cmc_rank} ${currency.currency_name}`}
                >
                  <Avatar
                    src={getCurrencyUrl(currency)}
                    size={_size}
                    radius={"lg"}
                    onClick={() => setSelectedCurrency(currency)}
                  />
                </Tooltip>
                <div className="fixed-currency-controls">
                  {pool === "medium" || pool === "low" ? (
                    <Tooltip
                      gutter={20}
                      position="left"
                      placement="end"
                      withArrow
                      label={
                        <label>
                          Move <strong>{currency.currency_name}</strong> to the{" "}
                          <strong>
                            {pool === "medium" ? "high" : "medium"}
                          </strong>{" "}
                          pool
                        </label>
                      }
                    >
                      <div
                        className="fixed-currency-control-button"
                        onClick={() => {
                          moveCurrencyToPool(
                            currency,
                            pool,
                            pool === "medium" ? "high" : "medium"
                          );
                        }}
                      >
                        <IoIosArrowBack />
                      </div>
                    </Tooltip>
                  ) : undefined}
                  {pool === "high" || pool === "medium" ? (
                    <Tooltip
                      gutter={20}
                      position="right"
                      placement="end"
                      withArrow
                      label={
                        <label>
                          Move <strong>{currency.currency_name}</strong> to the{" "}
                          <strong>{pool === "high" ? "medium" : "low"}</strong>{" "}
                          pool
                        </label>
                      }
                    >
                      <div
                        className="fixed-currency-control-button"
                        onClick={() => {
                          moveCurrencyToPool(
                            currency,
                            pool,
                            pool === "high" ? "medium" : "low"
                          );
                        }}
                      >
                        <IoIosArrowForward />
                      </div>
                    </Tooltip>
                  ) : undefined}
                </div>
              </div>
            ))}
          </>
        )}
      </motion.div>
    ),
    [moveCurrencyToPool, selectedCurrency, setSelectedCurrency]
  );

  const initializeFixedPoolComposition =
    useCallback((): FixedPoolComposition => {
      const _initializedFixedPoolComposition = {
        high: dynamicCurrencyPoolHigh.map((currency) => currency.currency_name),
        medium: dynamicCurrencyPoolMedium.map(
          (currency) => currency.currency_name
        ),
        low: dynamicCurrencyPoolLow.map((currency) => currency.currency_name),
      };
      return _initializedFixedPoolComposition;
    }, [
      dynamicCurrencyPoolHigh,
      dynamicCurrencyPoolLow,
      dynamicCurrencyPoolMedium,
    ]);

  const poolAnimation = {
    opacity: 1,
    minWidth: "fit-content",
    maxWidth: "50%",
    flex: "1",
  };

  return (
    <div className="public-header-editor-outer-container">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.5 }}
        className="public-header-editor-container"
      >
        <div className="public-header-row" id="funds-distribution-slider">
          <label className="dimmed-label public-header-label">
            Funds distribution
            <Tooltip
              className="public-header-info-tooltip"
              label={fundsDistribtutionTooltipDescription}
              position="bottom"
            >
              <AiOutlineInfoCircle size={tooltipIconSize} />
            </Tooltip>
          </label>
          <div className="slider-container">
            <RangeSlider
              label={null}
              min={0}
              max={100}
              minRange={0}
              value={[
                props.strategy.header.mc_distribution.high,
                props.strategy.header.mc_distribution.high +
                  props.strategy.header.mc_distribution.medium,
              ]}
              onChange={(value) => {
                updateHeader({
                  ...props.strategy.header,
                  mc_distribution: {
                    high: value[0],
                    medium: value[1] - value[0],
                    low: 100 - value[1],
                  },
                });
              }}
              labelAlwaysOn={false}
              radius={"xs"}
              color="green"
              styles={{
                thumb: {
                  height: 16,
                  width: 10,
                  borderWidth: 1,
                  borderRadius: 2,
                  borderColor: theme.orderOptionsTheme,
                  filter: "grayScale(0) !important",
                  backgroundColor: theme.orderOptionsTheme,
                },

                bar: {
                  backgroundColor: "transparent",
                },
                track: {
                  backgroundColor: theme.cardInterior,
                  borderRadius: "25%",
                  border: "1px solid " + theme.orderOptionsTheme,
                  filter: "grayScale(0.6)",
                },
              }}
              showLabelOnHover={false}
              size={"xs"}
            />
          </div>
        </div>

        {poolCompositionMode === "dynamic" && (
          <motion.div
            className="public-header-row"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.5 }}
          >
            <label className="dimmed-label public-header-label">
              MC Rank distribution
              <Tooltip
                wrapLines
                width={700}
                className="public-header-info-tooltip"
                label={rankDistributionTooltipDescription}
                position="bottom"
              >
                <AiOutlineInfoCircle size={tooltipIconSize} />
              </Tooltip>
            </label>
            <div className="slider-container">
              <RangeSlider
                min={1}
                minRange={2}
                value={[
                  props.strategy.header.rank_distribution.high,
                  props.strategy.header.rank_distribution.medium,
                ]}
                onChange={(value) => {
                  updateHeader({
                    ...props.strategy.header,
                    rank_distribution: {
                      high: value[0],
                      medium: value[1],
                    },
                  });
                }}
                labelAlwaysOn={false}
                radius={"xs"}
                color="cyan"
                styles={{
                  thumb: {
                    height: 16,
                    width: 10,
                    borderWidth: 1,
                    borderRadius: 2,
                    borderColor: theme.fragmentPattern,
                    filter: "grayScale(0) !important",
                    backgroundColor: theme.fragmentPattern,
                  },

                  bar: {
                    backgroundColor: "transparent",
                  },
                  track: {
                    backgroundColor: theme.cardInterior,
                    borderRadius: "25%",
                    border: "1px solid " + theme.fragmentPattern,
                    filter: "grayScale(0.2)",
                  },
                }}
                label={(value) => {
                  return `#${value}`;
                }}
                size={"xs"}
              />
            </div>
          </motion.div>
        )}
        <div className="public-header-row">
          <CommonButton
            activeTheme={props.activeTheme}
            id="pool-composition-switch"
            leftIcon={
              poolCompositionMode === "fixed" ? (
                <MdOutlineDynamicFeed />
              ) : (
                <MdDynamicForm />
              )
            }
            label={
              poolCompositionMode === "fixed" ? "Fixed pools" : "Dynamic pools"
            }
            onClick={() => {
              const fixedPoolComposition =
                props.strategy.header.fixed_pool_composition ??
                initializeFixedPoolComposition();
              updateHeader({
                ...props.strategy.header,
                pool_composition_type:
                  poolCompositionMode === "fixed" ? "dynamic" : "fixed",
                fixed_pool_composition: fixedPoolComposition,
              });
            }}
            compact
            flat
            borderTheme={
              poolCompositionMode === "fixed" ? "gray-accent" : "purple-accent"
            }
          />

          <div className="pool-display-container">
            <motion.div
              className={
                "pool-display" +
                (highMcPoolRemarksText
                  ? " remarks"
                  : highActivePoolLength === 0
                  ? " empty"
                  : "")
              }
              initial={{ opacity: 0 }}
              animate={poolAnimation}
              transition={{ duration: 0.3, delay: 0.6 }}
            >
              <Tooltip
                className="pool-display-label-container"
                label={
                  <>
                    <label>
                      <strong>{highPoolLabel}</strong>
                    </label>
                    <br />
                    {props.strategy.header.mc_distribution.high > 0 && (
                      <label>
                        <strong>
                          {(props.strategy.header.mc_distribution.high *
                            sampleAmount) /
                            100}
                          $
                        </strong>{" "}
                        to be allocated to this pool with a total of{" "}
                        <strong>{sampleAmount}$</strong>
                      </label>
                    )}
                    {highMcPoolRemarksText}
                  </>
                }
              >
                <div className="pool-display-label-row">
                  <label className="wallet-stat-label">
                    {props.strategy.header.mc_distribution.high}%
                  </label>
                </div>

                <div className="pool-display-label-row">
                  <label className="dimmed-label">{highPoolSubLabel}</label>
                </div>
              </Tooltip>
              {poolCompositionMode === "fixed"
                ? renderFixedPoolComposition(
                    fixedCurrencyPoolHigh,
                    highPoolIconSize,
                    "high"
                  )
                : renderDynamicPoolComposition(
                    dynamicCurrencyPoolHigh,
                    highPoolIconSize
                  )}
            </motion.div>
            <motion.div
              className={
                "pool-display" +
                (mediumMcPoolRemarksText
                  ? " remarks"
                  : mediumActivePoolLength === 0
                  ? " empty"
                  : "")
              }
              initial={{ opacity: 0 }}
              animate={poolAnimation}
              transition={{ duration: 0.3, delay: 0.6 }}
            >
              <Tooltip
                className="pool-display-label-container"
                label={
                  <>
                    <label>
                      <strong>{mediumPoolLabel}</strong>
                    </label>
                    <br />
                    {props.strategy.header.mc_distribution.medium > 0 && (
                      <label>
                        <strong>
                          {(props.strategy.header.mc_distribution.medium *
                            sampleAmount) /
                            100}
                          $
                        </strong>{" "}
                        to be allocated to this pool with a total of{" "}
                        <strong>{sampleAmount}$</strong>
                      </label>
                    )}
                    {mediumMcPoolRemarksText}
                  </>
                }
              >
                <div className="pool-display-label-row">
                  <label className="wallet-stat-label">
                    {props.strategy.header.mc_distribution.medium}%
                  </label>
                </div>
                <div className="pool-display-label-row">
                  <label className="dimmed-label">{mediumPoolSubLabel}</label>
                </div>
              </Tooltip>
              {poolCompositionMode === "fixed"
                ? renderFixedPoolComposition(
                    fixedCurrencyPoolMedium,
                    mediumPoolIconSize,
                    "medium"
                  )
                : renderDynamicPoolComposition(
                    dynamicCurrencyPoolMedium,
                    mediumPoolIconSize
                  )}
            </motion.div>
            <motion.div
              className={
                "pool-display" +
                (lowMcPoolRemarksText
                  ? " remarks"
                  : lowActivePoolLength === 0
                  ? " empty"
                  : "")
              }
              initial={{ opacity: 0 }}
              animate={poolAnimation}
              transition={{ duration: 0.3, delay: 0.6 }}
            >
              <Tooltip
                className="pool-display-label-container"
                label={
                  <>
                    <label>
                      <strong>{lowPoolLabel}</strong>
                    </label>
                    <br />
                    {props.strategy.header.mc_distribution.low > 0 && (
                      <label>
                        <strong>
                          {(props.strategy.header.mc_distribution.low *
                            sampleAmount) /
                            100}
                          $
                        </strong>{" "}
                        to be allocated to this pool with a total of{" "}
                        <strong>{sampleAmount}$</strong>
                      </label>
                    )}
                    {lowMcPoolRemarksText}
                  </>
                }
              >
                <div className="pool-display-label-row">
                  <label className="wallet-stat-label">
                    {props.strategy.header.mc_distribution.low}%
                  </label>
                </div>

                <div className="pool-display-label-row">
                  <label className="dimmed-label">{lowPoolSubLabel}</label>
                </div>
              </Tooltip>

              {poolCompositionMode === "fixed"
                ? renderFixedPoolComposition(
                    fixedCurrencyPoolLow,
                    lowPoolIconSize,
                    "low"
                  )
                : renderDynamicPoolComposition(
                    dynamicCurrencyPoolLow,
                    lowPoolIconSize
                  )}
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
