import {
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import {
  ThresholdData,
  ThresholdModifierPayload,
} from "../../strategy-body/case-control/code-fragments-overviews/AICodeFragmentsOverview";
import { useContext, useMemo } from "react";
import { toUTCTimestring } from "../../../utils/FormattingUtils";
import { getTheme } from "../../../utils/themeUtil";
import { CurrencyPriceData } from "../../../interfaces/PricesOverview";
import { IndicatorsContext } from "../../../shared-service-contexts/IndicatorContext";

interface Props {
  activeTheme: string;
  thresholdModifierPayload: ThresholdModifierPayload;
  thresholdData: ThresholdData;
  priceData: CurrencyPriceData | undefined;
  entryThreshold: number;
  exitThreshold: number;
  regressionMultiplier: number;
  setThresholdWindowHits: React.Dispatch<React.SetStateAction<number>>
}
export function ThresholdChart(props: React.PropsWithChildren<Props>) {
  const theme = getTheme(props.activeTheme);
  const applicationIndicators = useContext(IndicatorsContext);
  const thresholdValueSeries = useMemo(() => {
    if (!props.thresholdData) return undefined;
    const series: { x: string; regressionValue: number }[] = [];
    Object.keys(props.thresholdData).forEach((timestamp) => {
      const timestampString = toUTCTimestring(
        parseInt(timestamp),
        props.thresholdModifierPayload.candleSize
      );
      series.push({
        x: timestampString,
        regressionValue:
          props.thresholdData[timestamp] * props.regressionMultiplier,
      });
    });
    return series;
  }, [
    props.thresholdData,
    props.thresholdModifierPayload,
    props.regressionMultiplier,
  ]);

  const priceDataSeries = useMemo(() => {
    if (!props.priceData || !thresholdValueSeries) return undefined;
    const thresholdTimeStamps = thresholdValueSeries.map((entry) => entry.x);
    const series: { x: string; close: number }[] = [];
    for (let i = 0; i < props.priceData.prices.length; i++) {
      const timestring = toUTCTimestring(
        props.priceData.timestamps[i],
        props.thresholdModifierPayload.candleSize
      );
      if (!thresholdTimeStamps.includes(timestring)) continue;
      series.push({
        x: timestring,
        close: props.priceData.prices[i],
      });
    }
    return series;
  }, [props.priceData, props.thresholdModifierPayload, thresholdValueSeries]);

  const tresholdAxisYDomain = useMemo(() => {
    const values = [props.entryThreshold, props.exitThreshold];
    let min = Math.min(...values);
    let max = Math.max(...values);

    const padding = (max - min) * 0.1;
    return [min - padding, max + padding];
  }, [props.entryThreshold, props.exitThreshold]);

  const priceAxisYDomain = useMemo(() => {
    if (!priceDataSeries) return undefined;
    const prices = priceDataSeries.map((entry) => entry.close);
    let min = Math.min(...prices);
    let max = Math.max(...prices);

    const padding = (max - min) * 0.1;
    return [min - padding, max + padding];
  }, [priceDataSeries]);

  const projectedOrders = useMemo(() => {
    if (!applicationIndicators) return undefined;
    if (!thresholdValueSeries) return undefined;
    const activeCriteriaLogic =
    applicationIndicators.ai_translations[
      `${
        props.thresholdModifierPayload.indicatorName
      }_${props.thresholdModifierPayload.criteriaType.toUpperCase()}`
    ];
    if (!activeCriteriaLogic) return undefined;
    const aliasTranslation =
    applicationIndicators.ai_alias_translations[
      props.thresholdModifierPayload.indicatorName
    ];
    // replace alias translation with props.thresholdModifierPayload.indicatorName using regex
    const regex = new RegExp(aliasTranslation, "g");
    const logic = activeCriteriaLogic.replace(
      regex,
      props.thresholdModifierPayload.indicatorName
    );
    const thresholdVariable = `${
      props.thresholdModifierPayload.indicatorName
    }_${props.thresholdModifierPayload.criteriaType.toUpperCase()}_THRESHOLD`;
    const regressionVariable = `${props.thresholdModifierPayload.indicatorName}_REGRESSION_VALUE`;
    const projectedOrderTimestamps: string[] = [];
    
    thresholdValueSeries.forEach((entry) => {
      const thresholdValue =
      props.thresholdModifierPayload.criteriaType.toUpperCase() === "ENTRY"
      ? props.entryThreshold
      : props.exitThreshold;
      const regressionValue = entry.regressionValue;
      
      // Dynamically declare variables with let inside eval
      const evalString = `
      let ${thresholdVariable} = ${thresholdValue};
      let ${regressionVariable} = ${regressionValue};
      ${logic}
      `;
      if (eval(evalString)) {
        projectedOrderTimestamps.push(entry.x);
      }
    });

    const strokeColor =
      props.thresholdModifierPayload.criteriaType.toUpperCase() === "ENTRY"
        ? theme.buyOrderStroke
        : theme.sellOrderStroke;
    props.setThresholdWindowHits(projectedOrderTimestamps.length)
    return projectedOrderTimestamps.map((timestamp) => {
      return (
        <ReferenceLine
          key={timestamp}
          x={timestamp}
          stroke={strokeColor}
          strokeWidth={2}
        />
      );
    });
  }, [
    props.thresholdModifierPayload,
    thresholdValueSeries,
    applicationIndicators,
    props.entryThreshold,
    props.exitThreshold,
    theme,
  ]);

  return (
    <div className="threshold-chart-outer-container">
      <div className="threshold-chart-container">
        <ResponsiveContainer width="99%" height={"99%"}>
          <LineChart width={400} height={100} data={thresholdValueSeries}>
            <XAxis dataKey="x" hide />{" "}
            {tresholdAxisYDomain && (
              <YAxis
                domain={[tresholdAxisYDomain[0], tresholdAxisYDomain[1]]}
                hide
              />
            )}
            {props.thresholdModifierPayload.criteriaType.toUpperCase() ===
              "ENTRY" && (
              <ReferenceLine
                y={props.entryThreshold}
                stroke={theme.buyOrderStroke}
                strokeWidth={2}
              />
            )}
            {props.thresholdModifierPayload.criteriaType.toUpperCase() ===
              "EXIT" && (
              <ReferenceLine
                y={props.exitThreshold}
                stroke={theme.sellOrderStroke}
                strokeWidth={2}
              />
            )}
            <Line
              type="monotone"
              dataKey="regressionValue"
              stroke={theme.neotonTransparent}
              strokeWidth={2}
              activeDot={{ r: 0 }}
              dot={{ r: 0 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="threshold-price-chart-container">
        <ResponsiveContainer width="99%" height={"99%"}>
          <LineChart width={400} height={100} data={priceDataSeries}>
            <XAxis dataKey="x" hide />
            {priceAxisYDomain && (
              <YAxis domain={[priceAxisYDomain[0], priceAxisYDomain[1]]} hide />
            )}
            <Line
              type="monotone"
              dataKey="close"
              stroke={theme.text}
              strokeWidth={2}
              activeDot={{ r: 0 }}
              dot={{ r: 0 }}
            />
            {projectedOrders}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
