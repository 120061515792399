import "./achievement.scss";
import { motion, useInView } from "framer-motion";
import { getAchievementUrl } from "../../../utils/cdnUtil";
import { Avatar, Popover } from "@mantine/core";
import { UserAchievement } from "../../../services/UserManagerService";
import { toTimestring } from "../../../utils/FormattingUtils";
import { GrAchievement } from "react-icons/gr";
import { useMemo } from "react";
import { getTheme } from "../../../utils/themeUtil";

interface Props {
  activeTheme: string;
  userAchievement: UserAchievement;
  focusedAchievementKey: string | undefined;
  setFocusedAchievementKey: (key: string | undefined) => void;
  compact?: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
}
export function Achievement(props: React.PropsWithChildren<Props>) {
  const theme = useMemo(() => {
    return getTheme(props.activeTheme);
  }, [props.activeTheme]);
  const isInView = useInView(props.containerRef);

  const achievementUrl = getAchievementUrl(
    props.userAchievement.achievement_key
  );

  return (
    <motion.div
      className={"achievement-container" + (props.compact ? " compact" : "")}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, filter: "contrast(1.1)" }}
      whileHover={{ filter: "contrast(1.5)" }}
      onClick={(e: any) => {
        e?.stopPropagation();
        props.setFocusedAchievementKey(
          props.userAchievement.name === props.focusedAchievementKey
            ? undefined
            : props.userAchievement.name
        );
      }}
    >
      <Popover
        gutter={-10}
        position="bottom"
        shadow="xl"
        withArrow
        className="achievement-popover-container"
        classNames={{
          inner: "notifications-popover-inner",
          target: "notifications-popover-target",
          arrow: "notifications-popover-arrow",
          body: "notifications-popover-body",
          root: "notifications-popover-root",
          wrapper: "notifications-popover-wrapper",
          popover: "notifications-popover-popover",
        }}
        opened={
          isInView && props.focusedAchievementKey === props.userAchievement.name
        }
        onClose={() => props.setFocusedAchievementKey(undefined)}
        target={<Avatar src={achievementUrl} size="xl" radius={"xl"} />}
      >
        <motion.div
          className="achievement-details-container"
          initial={{ opacity: 0 }}
          animate={{
            opacity:
              props.focusedAchievementKey === props.userAchievement.name
                ? 1
                : 0,
          }}
        >
          <label className="achievement-header-label">
            <GrAchievement stroke={theme.cardInteriorHover} />

            {props.userAchievement.name}
          </label>
          <label className="achievement-description-label">
            {props.userAchievement.description}
          </label>
          <label className="dimmed-label">
            {toTimestring(props.userAchievement.earned)}
          </label>
        </motion.div>
      </Popover>
    </motion.div>
  );
}
