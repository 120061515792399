import { Badge, MantineNumberSize, BadgeVariant } from "@mantine/core";
import { FragmentType } from "../../../utils/ParseCriteriaUtil";
import { Indicators } from "../../../interfaces/Indicators";

interface Props {
  activeTheme: string;
  fragmentType: FragmentType;
  index?: number;
  preDeterminedFragment?: PreDeterminedFragment;
  indicators: Indicators;
  small?: boolean;
  large?: boolean;
  //add ai indicators alias
}

export function CriteriaFragment(props: React.PropsWithChildren<Props>) {
  if (props.preDeterminedFragment) {
    return (
      <Badge
        size={props.small ? "xs" : props.large ? "lg" : "sm"}
        className={props.preDeterminedFragment.className}
        color={props.preDeterminedFragment.color}
        radius={props.preDeterminedFragment.radius ?? "xl"}
        variant={props.preDeterminedFragment.variant}
      >
        {props.fragmentType.type === "CONNECTOR"
          ? "&"
          : props.fragmentType.fragment}
      </Badge>
    );
  }
  if (props.fragmentType.type === "DIRECTION") {
    return (
      <Badge
        size={props.small ? "xs" : props.large ? "lg" : "sm"}
        className={props.fragmentType.className}
        color="teal"
        radius="xs"
        variant={"filled"}
      >
        {props.fragmentType.fragment}
      </Badge>
    );
  }
  if (props.fragmentType.type === "OPERATOR") {
    return (
      <Badge
        size={props.small ? "xs" : props.large ? "lg" : "sm"}
        className={props.fragmentType.className}
        radius={"xs"}
        color="dark"
        variant={"outline"}
      >
        {props.fragmentType.fragment}
      </Badge>
    );
  }
  if (props.fragmentType.type === "VALUE") {
    return (
      <Badge
        size={props.small ? "xs" : props.large ? "lg" : "sm"}
        className={props.fragmentType.className}
        //color="blue"
        radius="xs"
        variant={"filled"}
      >
        {props.fragmentType.fragment}
      </Badge>
    );
  }
  if (props.fragmentType.type === "CONNECTOR") {
    return (
      <Badge
        size={props.small ? "xs" : props.large ? "lg" : "sm"}
        className={props.fragmentType.className}
        color="dark"
        variant={"outline"}
      >
        &
      </Badge>
    );
  }

  /* if (isValue(props.fragmentType.fragment)) {
    return (
      <Badge
        size={props.small ? "xs" : props.large ? "lg" : "sm"}
        className="fragment-badge-number"
        color="blue"
        radius="xs"
        variant={"filled"}
      >
        {props.fragmentType.fragment}
      </Badge>
    );
  } */

  if (props.fragmentType.type === "PRICE_HEAD") {
    return (
      <Badge
        size={props.small ? "xs" : props.large ? "lg" : "sm"}
        className={props.fragmentType.className}
        color="teal"
        radius="xs"
        variant={"filled"}
      >
        {props.fragmentType.fragment}
      </Badge>
    );
  }
  if (props.fragmentType.type === "PATTERN") {
    return (
      <Badge
        size={props.small ? "xs" : props.large ? "lg" : "sm"}
        className={props.fragmentType.className}
        color="teal"
        radius="xs"
        variant={"filled"}
      >
        {props.fragmentType.fragment}
      </Badge>
    );
  }

  if (props.fragmentType.type === "CANDLE") {
    return (
      <Badge
        size={props.small ? "xs" : props.large ? "lg" : "sm"}
        className={props.fragmentType.className}
        color="teal"
        radius="xs"
        variant={"filled"}
      >
        {props.fragmentType.fragment}
      </Badge>
    );
  }

  return (
    <Badge
      size={props.small ? "xs" : props.large ? "lg" : "sm"}
      className={props.fragmentType.className}
      color="cyan"
      radius="xs"
      variant={"filled"}
    >
      {props.fragmentType.modifierRegexPattern && props.fragmentType.modifierReplacement ? props.fragmentType.fragment.replace(props.fragmentType.modifierRegexPattern, props.fragmentType.modifierReplacement) : props.fragmentType.fragment}
    </Badge>
  );
}

export interface PreDeterminedFragment {
  className: string;
  color?: string;
  radius?: MantineNumberSize;
  variant: BadgeVariant;
}
