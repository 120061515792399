import axios, { AxiosInstance } from "axios";
import { createOptions } from "./CreateOptions";
import { CustomResponse } from "../interfaces/Response";
import BlueprintService from "./BlueprintService";
import { PublicUserInfo, UserInfo } from "../interfaces/user/UserInfo";
import { AdminPanelData } from "../interfaces/admin/AdminPanelData";
import { CurrencyDatasetResponse } from "../interfaces/admin/CurrencyDataset";
import { Changelog } from "../pages/home-page/home-page-cards/ChangelogComponent";
import { StripeSubscriptions } from "../interfaces/stripe/StripInterfaces";
import { StripeInvoice } from "../pages/home-page/home-page-cards/SubscriptionsModal";
import {
  ApplicationNotifications,
  GeneralNotification,
} from "../interfaces/notifications/Notifications";

const http = axios.create({
  baseURL: process.env.REACT_APP_USERMANAGER_API,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

class UsermanagerServiceClass {
  public axiosInstance: AxiosInstance;
  public blueprint: BlueprintService;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    this.blueprint = new BlueprintService(axiosInstance);
  }

  public getPublicUserInfo = async (token: string, user_hash: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<PublicUserInfo>>(
      `public-user-info/${user_hash}`,
      options
    );
  };
  public getUserInfo = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<UserInfo>>("/user-info", options);
  };

  public updateUser = async (token: string, form: UserInfoUpdateForm) => {
    const options = createOptions(token);
    return await http.post<CustomResponse<any>>(
      "/update-user-info",
      form,
      options
    );
  };

  public getStarterProfilePictures = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<string[]>>(
      "/starter-profile-pics",
      options
    );
  };

  public uploadProfilePicture = async (token: string, imageFile: File) => {
    const options = createOptions(token);
    const formData = new FormData();
    formData.append("image", imageFile);

    return await http.post<CustomResponse<string>>(
      "/upload-profile-picture",
      formData,
      options
    );
  };

  // beta endpoints
  public getNonce = async () => {
    return await http.get<any>("/get-nonce");
  };

  public submitBetaSignupForm = async (form: BetaSignupForm) => {
    return await http.post<CustomResponse<any>>("/beta-signup", form);
  };

  public submitBetaCode = async (token: string, form: BetaCodeSubmitForm) => {
    const options = createOptions(token);
    return await http.post<CustomResponse<any>>(
      "/activate-beta",
      form,
      options
    );
  };

  // support

  public getLivetraderLogEvaluation = async (
    token: string,
    livetraderLogId: string
  ) => {
    const options = createOptions(token);
    const body = {
      livetrader_log_id: livetraderLogId,
    };
    return await http.post<CustomResponse<LivetraderLogExplanation>>(
      "/livetrader/log-eval",
      body,
      options
    );
  };

  public submitLivetraderBugReport = async (
    token: string,
    livetraderLogId: string
  ) => {
    const options = createOptions(token);
    const body = {
      livetrader_log_id: livetraderLogId,
    };
    return await http.post<CustomResponse<any>>(
      "/livetrader/submit-bug",
      body,
      options
    );
  };

  public getBugImageUploadUri = async (token: string, blobName: string) => {
    const options = createOptions(token);
    const body = {
      blob_name: blobName,
    };
    return await http.post<CustomResponse<UploadSasResponse>>(
      "/get-bug-upload-uri",
      body,
      options
    );
  };

  public submitBugReport = async (token: string, form: BugReportForm) => {
    const options = createOptions(token);
    return await http.post<CustomResponse<any>>("/submit-bug", form, options);
  };

  // ADMIN
  public submitFeed = async (token: string, feedForm: FeedForm) => {
    const options = createOptions(token);
    return await http.post<CustomResponse<any>>(
      "/submit-feed",
      feedForm,
      options
    );
  };

  public deleteFeed = async (token: string, feedId: number) => {
    const options = createOptions(token);
    const body = {
      feed_id: feedId,
    };
    return await http.post<CustomResponse<any>>("/delete-feed", body, options);
  };

  public adminPanelData = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<AdminPanelData>>(
      "/admin-panel-data",
      options
    );
  };
  public getCurrencyDataset = async (token: string, currencyName: string) => {
    const options = createOptions(token);

    return await http.get<CurrencyDatasetResponse>(
      `/live-dataset/${currencyName}`,
      options
    );
  };
  public prioritizeCurrency = async (token: string, currencyName: string) => {
    const options = createOptions(token);
    const body = {
      currency_name: currencyName,
    };
    return await http.post<CustomResponse<string[]>>(
      "/prioritize-currency",
      body,
      options
    );
  };

  public getPrioritizedCurrencies = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<string[]>>(
      "/prioritized-currencies",
      options
    );
  };

  public getChangelog = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<Changelog[]>>("/changelog", options);
  };

  public AcceptTermsOfService = async (token: string) => {
    const options = createOptions(token);
    return await http.post<CustomResponse<UserInfo>>(
      "/accept-terms-of-service",
      {},
      options
    );
  };

  public AcceptPrivacyPolicy = async (token: string) => {
    const options = createOptions(token);
    return await http.post<CustomResponse<UserInfo>>(
      "/accept-privacy-policy",
      {},
      options
    );
  };

  public ResendActivationEmail = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<any>>(
      "/resend-activation-email",
      options
    );
  };

  public ReportUser = async (
    token: string,
    userHash: string,
    reason: string
  ) => {
    const options = createOptions(token);
    const body = {
      user_hash: userHash,
      reason: reason,
    };
    return await http.post<CustomResponse<any>>("/report-user", body, options);
  };

  public RequestResolveReport = async (token: string, reason: string) => {
    const options = createOptions(token);
    const body = {
      reason: reason,
    };
    return await http.post<CustomResponse<any>>(
      "/request-report-resolve",
      body,
      options
    );
  };

  public GetUserViolationDetails = async (
    token: string,
    violationReportId: string
  ) => {
    const options = createOptions(token);
    const body = {
      violation_report_id: violationReportId,
    };
    return await http.post<CustomResponse<UserViolationDetails>>(
      "/violation-details",
      body,
      options
    );
  };

  public GetViolationReports = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<ViolationReport[]>>(
      "/violation-reports",
      options
    );
  };

  public MarkUserViolation = async (
    token: string,
    reportId: string,
    reason: string
  ) => {
    const options = createOptions(token);
    const body = {
      report_id: reportId,
      reason: reason,
    };
    return await http.post<CustomResponse<any>>(
      "/mark-user-violation",
      body,
      options
    );
  };

  public UnmarkUserViolation = async (token: string, reportId: string) => {
    const options = createOptions(token);
    const body = {
      violation_report_id: reportId,
    };
    return await http.post<CustomResponse<any>>(
      "/unmark-user-violation",
      body,
      options
    );
  };

  public GetSubscriptionTier = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<UserSubscriptionTier | null>>(
      "/subscription-tier",
      options
    );
  };

  public GetSubscriptions = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<StripeSubscriptions>>(
      "/subscriptions",
      options
    );
  };

  public GetSubscriptionAmount = async (
    token: string,
    plan: string,
    interval: string
  ) => {
    const options = createOptions(token);
    const body = {
      subscription_plan: plan,
      interval: interval,
    };
    return await http.post<CustomResponse<number>>(
      "/subscription-amount",
      body,
      options
    );
  };

  public GetCheckoutSession = async (
    token: string,
    plan: string,
    interval: string
  ) => {
    const options = createOptions(token);
    const body = {
      subscription_plan: plan,
      interval: interval,
      node_env: process.env.NODE_ENV,
    };
    return await http.post<CustomResponse<any>>(
      "/create-checkout-session",
      body,
      options
    );
  };
  public CancelSubscription = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<number | null>>(
      "/subscription-cancel",
      options
    );
  };

  public GetStripeSession = async (token: string, sessionId: string) => {
    const options = createOptions(token);
    const body = {
      session_id: sessionId,
    };
    return await http.post<CustomResponse<any>>(
      "/subscription-session",
      body,
      options
    );
  };
  public GetStripeInvoices = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<StripeInvoiceResponse>>(
      "/invoices",
      options
    );
  };

  public GetNotifications = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<ApplicationNotifications>>(
      "/notifications",
      options
    );
  };

  public MarkNotificationsRead = async (
    token: string,
    notificationIds: string[]
  ) => {
    const options = createOptions(token);
    const body = {
      notification_ids: notificationIds,
    };
    return await http.post<CustomResponse<GeneralNotification[]>>(
      "/mark-notifications-read",
      body,
      options
    );
  };

  public ClearSelectedNotifications = async (
    token: string,
    notificationIds: string[]
  ) => {
    const options = createOptions(token);
    const body = {
      notification_ids: notificationIds,
    };
    return await http.post<CustomResponse<GeneralNotification[]>>(
      "/clear-notifications",
      body,
      options
    );
  };

  public MarkTradingNotificationsRead = async (token: string) => {
    const options = createOptions(token);
    return await http.post<CustomResponse<any>>(
      "/mark-trading-notifications-read",
      {},
      options
    );
  };
  public ClearTradingNotifications = async (token: string) => {
    const options = createOptions(token);
    return await http.post<CustomResponse<any>>(
      "/clear-trading-notifications",
      {},
      options
    );
  };

  public GetUserAchievements = async (token: string, user_hash: string) => {
    const options = createOptions(token);
    const body = {
      user_hash: user_hash,
    };
    return await http.post<CustomResponse<UserAchievement[]>>(
      "/get-achievement-details",
      body,
      options
    );
  };
}

export const UsermanagerService = new UsermanagerServiceClass(http);

export interface UserAchievement {
  achievement_key: string;
  earned: number;
  description: string;
  name: string;
}

export interface StripeInvoiceResponse {
  data: StripeInvoice[];
}

export interface UserSubscriptionTier {
  plan: string;
  expires: number;
  product_id: string;
  role_id: string;
  cancelled: boolean;
  interval: string;
  past_due?: boolean;
}

export interface UserViolationDetails {
  user_details: UserInfo;
  strategies: string[];
}

export interface BetaSignupForm {
  nonce: string;
  email: string;
  first_name: string;
  last_name: string;
  country: string;
}

export interface BetaCodeSubmitForm {
  nonce: string;
  code: string;
}

export interface BugReportForm {
  page: string;
  subject: string;
  description: string;
  blob_id: string | undefined;
  nonce: string;
}

export interface UploadSasResponse {
  sas_uri: string;
  blob_id: string;
}

export interface UserInfoUpdateForm {
  username: string;
  blob_id: string | undefined;
}

export interface FeedForm {
  message: string;
  feed: "global" | "backtester" | "papertrader" | "livetrader";
  expiry?: number;
}

export interface LivetraderLogExplanation {
  explanation: string;
}

export interface ViolationReport {
  id: string;
  reason: string;
  request_resolve: boolean;
  reporter_user_id?: string;
}
