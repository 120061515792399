import "./strategy-center.scss";
import { useCallback, useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Candles } from "../../interfaces/Candle";
import { Currency } from "../../interfaces/Currency";
import { StrategyVersion } from "../../interfaces/strategyInterfaces/Strategy";
import { getAllowedTimeInterval } from "../../utils/CandleCountUtil";
import { StrategyChartContainer } from "./StrategyChartContainer";
import { useAuth0 } from "@auth0/auth0-react";
import { StrategyManagerService } from "../../services/StrategyManagerService";
import { SelectedCurrencyContext } from "../shared-context/HandleCurrencyContext";
import { SelectedCaseContext } from "../../pages/strategy-page/StrategyPage";

interface Props {
  activeTheme: string;
  compact?: boolean;
  strategy: StrategyVersion;
  availableCurrencies: Currency[];
}

const allowedCandleAmount = 1200;

export function StrategyCenter(props: React.PropsWithChildren<Props>) {
  const now = useMemo(() => new Date().getTime(), []);
  const minDate = useMemo(() => 1507840400000, []);
  const selectedCurrency = useContext(SelectedCurrencyContext);
  const { getAccessTokenSilently } = useAuth0();
  const [loadingCandles, setLoadingCandles] = useState(false);

  const [candleSize, setCandleSize] = useState("1D");
  const [candles, setCandles] = useState<Candles | undefined>();

  const selectedCase = useContext(SelectedCaseContext);

  const [timeInterval, setTimeInterval] = useState<number[]>([minDate, now]);

  const handleCandleSizeChange = useCallback(
    (candleSize: string, centerTimestamp: number | null) => {
      if (!candles) {
        return;
      }
      if (centerTimestamp === null) {
        const length = candles.candleData.timestamps.length / 2;
        const centerIndex = parseInt(length.toString(), 10);
        centerTimestamp = candles.candleData.timestamps[centerIndex];
      }
      if (candleSize === "1D") {
        const stop = now;
        const start = minDate;
        setTimeInterval([start, stop]);
        setCandleSize(candleSize);
        return;
      }
      const allowedInterval = getAllowedTimeInterval(
        now,
        allowedCandleAmount,
        candleSize,
        centerTimestamp
      );
      setTimeInterval(allowedInterval);
      setCandleSize(candleSize);
    },
    [candles, setTimeInterval, now, minDate]
  );

  const fetchCandles = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token || !selectedCurrency) return;
    try {
      setLoadingCandles(true);

      const candleResponse = await StrategyManagerService.blueprint.getCandles(
        token,
        selectedCurrency.currency_name,
        candleSize,
        timeInterval[0],
        timeInterval[1]
      );

      setCandles(candleResponse.data);
      if (candleResponse.data.candleData) {
        setTimeInterval([
          candleResponse.data.candleData.timestamps[0],
          candleResponse.data.candleData.timestamps[
            candleResponse.data.candleData.timestamps.length - 1
          ],
        ]);
      }
    } finally {
      setLoadingCandles(false);
    }
  }, [
    getAccessTokenSilently,
    selectedCurrency,
    timeInterval,
    candleSize,
    setTimeInterval,
    setCandles,
  ]);

  useQuery(["candles", timeInterval, selectedCurrency], fetchCandles, {
    cacheTime: 2000,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!selectedCurrency,
  });

  return (
    <StrategyChartContainer
      strategy={props.strategy}
      activeTheme={props.activeTheme}
      availableCurrencies={props.availableCurrencies}
      candleSize={candleSize}
      handleCandleSizeChange={handleCandleSizeChange}
      candles={candles}
      timeInterval={timeInterval}
      selectedCase={selectedCase}
      loadingCandles={loadingCandles}
    />
  );
}
